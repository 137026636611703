import create from 'zustand';

const useCourseStore = create((set) => ({
  courses: [],
  coursesById: {},
  isFetching: false,
  courseGettingEdited: null,
  setCourses: (courses) =>
    set(() => ({
      courses,
      coursesById: courses.reduce(
        (acc, course) => ({ ...acc, [course.id]: course }),
        {}
      ),
    })),
  setIsFetching: (isFetching) => set(() => ({ isFetching })),
  courseCount: null,
  setCourseCount: (courseCount) => set(() => ({ courseCount })),
  editCourse: (id) => set({ courseGettingEdited: id }),
  isDeleteCourse: false,
  setIsDeleteCourse: (isDeleteCourse) => set(() => ({ isDeleteCourse })),
  isDeletingSnackbar: false,
  setIsDeletingSnackbar: (isDeletingSnackbar) =>
    set(() => ({ isDeletingSnackbar })),
  deleteCourseId: null,
  setDeleteCourseId: (deleteCourseId) => set(() => ({ deleteCourseId })),
  addingCourse: false,
  setAddingCourse: (addingCourse) => set(() => ({ addingCourse })),
  addEditCourseId: null,
  setAddEditCourseId: (addEditCourseId) => set(() => ({ addEditCourseId })),
  isDeletingCourse: false,
  setIsDeletingCourse: (isDeletingCourse) => set(() => ({ isDeletingCourse })),
  deletingCourseId: null, // To store the id of the course that is being deleted
  setDeletingCourseId: (deletingCourseId) => set(() => ({ deletingCourseId })),
  isCopyingCourse: false,
  setIsCopyingCourse: (isCopyingCourse) => set(() => ({ isCopyingCourse })),
  copyCourseId: null,
  setCopyCourseId: (copyCourseId) => set(() => ({ copyCourseId })),
}));

export default useCourseStore;
