import { create } from 'zustand';

export const useGPAStore = create((set) => ({
  gpaCount: 0,
  setGpaCount: (gpaCount) => set(() => ({ gpaCount })),

  isFetchingScores: false,
  setIsFetchingScores: (isFetchingScores) => set(() => ({ isFetchingScores })),

  IsAddingGPAScore: false,
  setIsAddingGPAScore: (IsAddingGPAScore) => set(() => ({ IsAddingGPAScore })),

  gpaScores: [],
  setGpaScores: (gpaScores) => set(() => ({ gpaScores })),

  isEditingGpaScore: false,
  setIsEditingGpaScore: (isEditingGpaScore) =>
    set(() => ({ isEditingGpaScore })),
}));
