import { Avatar } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { InputField } from './InputField';
import { useEffect, useState } from 'react';
import Button from './Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Close } from '@mui/icons-material';

export const AutoComplete = ({
  label = 'Auto Complete',
  options = [],
  background = 'white',
  value = [],
  isAddNewButton = false,
  onAddNew = () => {},
  onClose = () => {},
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    if (value?.length > 0) {
      setSelectedValue([value[value?.length - 1]]);
    } else {
      setSelectedValue([]);
    }
  }, [value]);

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedValue || []}
      PaperComponent={({ children }) => (
        <div
          className="mt-1 bg-white border border-[#F4F4F4] rounded-lg shadow-1lg"
          onMouseDown={(e) => e.preventDefault()}
        >
          {children}
          {isAddNewButton && (
            <div className="px-5 py-4">
              <Button
                variant="text"
                className="w-fit"
                startIcon={<AddCircleIcon />}
                onClick={onAddNew}
              >
                Add New
              </Button>
            </div>
          )}
        </div>
      )}
      ListboxComponent={(props) => (
        <div className="p-2 flex flex-col gap-1 h-full max-h-60 overflow-auto">
          <ul {...props} className="flex flex-col gap-1" />
        </div>
      )}
      renderInput={(params) => (
        <InputField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedValue?.length > 0 && (
              <div className="flex items-center gap-2 px-3 py-2 bg-grey-10 rounded-full">
                <Avatar
                  src={selectedValue[0]?.collegeData?.collegeLogoURL}
                  sx={{ height: '20px', width: '20px' }}
                />
                <div className="text-grey-900 font-medium">
                  {' '}
                  {selectedValue[0]?.collegeData?.collegeName}
                </div>
                <Close
                  className="cursor-pointer"
                  onClick={onClose}
                  sx={{
                    height: '14px',
                    width: '14px',
                  }}
                />
              </div>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};
