import Dialog from './Dialog';
const DeleteDialog = ({ title, buttonText, children, ...args }) => {
  return (
    <Dialog
      title={title}
      {...args}
      continueText={buttonText ? buttonText : 'Delete'}
      sx={{
        '.MuiButton-contained ': {
          backgroundColor: '#DB4F59',
          ':hover': {
            backgroundColor: '#D1333E',
          },
        },
        '.MuiButton-text ': {
          color: '#838489',
        },
      }}
    >
      <div className="flex flex-col gap-2">{children}</div>
    </Dialog>
  );
};

export default DeleteDialog;
