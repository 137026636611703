import { Launch } from '@mui/icons-material';
import { Divider, IconButton } from '@mui/material';
import ChatBodyHeader from './ChatBodyHeader';
import { ReactComponent as MessageIcon } from '../../assets/svgs/messageIcon.svg';
import ChatBubbleGroup from './ChatBubbleGroup';
import ChatInputField from './ChatInputField';
import { useEffect, useMemo, useState } from 'react';
import { useStoryItemListener } from '../../hooks';
import { CHAT_MESSAGES, CHAT_ROOMS } from '../../firebase/constants';
import { firebaseDataType } from '../../constants/other';
import dayjs from '../../utils/dayjs';
import { getSingularUtility } from '../../utils/helper';
import useActivityStore from '../../stores/ActivityStore';
import useHonorStore from '../../stores/HonorStore';
import useNicheStore from '../../stores/NicheStore';
import moment from 'moment';
import useApplicationStore from '../../stores/ApplicationStore';
import useEssayStore from '../../stores/EssayStore';
import { firebaseQueryOperators } from '../../firebase/queryBuilder';

const ChatBody = ({
  openChatId = '',
  userId = '',
  utility = '',
  topicName = '',
  onChatBack = () => {},
  onSendMessage = () => {},
  teamMembers = [],
  onOpenUtility = () => {},
  onEditTopic = () => {},
  archivedBy = [],
  onArchiveTopicMessage = () => {},
  openChatType = '',
  createdAt = '',
  receipientDetails = {},
  teamMemberExists = false,
  utilityId = '',
  markMessagesAsRead = () => {},
}) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [isArchived, setIsArchived] = useState(false);
  const activityStore = useActivityStore();
  const applicationStore = useApplicationStore();
  const essayStore = useEssayStore();
  const honorStore = useHonorStore();
  const nicheStore = useNicheStore();
  const createdDate = dayjs.utc(createdAt).local().format('DD MMMM YYYY');
  const {
    data: chatMessageData,
    // eslint-disable-next-line no-unused-vars
    count: chatMessageCount,
    isFetching: isFetchingMessageData,
  } = useStoryItemListener(
    `${CHAT_ROOMS}/${openChatId}/${CHAT_MESSAGES}`,
    firebaseDataType.METADATA,
    [
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ]
  );

  const LAST_ONE_HOUR_MESSAGES = useMemo(() => {
    if (chatMessageData && chatMessageData?.length > 0) {
      return chatMessageData?.filter(({ createdAt }) =>
        moment(createdAt?.toDate()).isSameOrAfter(moment().subtract(1, 'hour'))
      );
    } else return [];
  }, [chatMessageData]);

  useEffect(() => {
    setIsArchived(archivedBy.includes(userId));
    //eslint-disable-next-line
  }, [archivedBy]);

  useEffect(() => {
    if (chatMessageData?.length > chatMessages?.length && openChatId) {
      markMessagesAsRead(
        openChatId,
        chatMessageData?.length - chatMessages?.length
      );
    }
    setChatMessages(
      chatMessageData?.sort((message1, message2) => {
        return new Date(message1.timeStamp) - new Date(message2.timeStamp);
      })
    );
    //eslint-disable-next-line
  }, [chatMessageData, isFetchingMessageData]);

  const isUtilityPresent = () => {
    if (utility === 'Activities') {
      if (activityStore.activitiesById[utilityId]) {
        return true;
      }
    } else if (utility === 'Honors') {
      if (honorStore.honorsById[utilityId]) {
        return true;
      }
    } else if (utility === 'Niche') {
      if (nicheStore.nichesById[utilityId]) {
        return true;
      }
    } else if (utility === 'Colleges') {
      if (applicationStore.applicationsById[utilityId]) {
        return true;
      }
    } else if (utility === 'Essays') {
      if (essayStore.essaysById[utilityId]) {
        return true;
      }
    } else if (
      utility === 'Courses' ||
      utility === 'My Story' ||
      utility === 'Tests'
    ) {
      return true;
    }

    return false;
  };

  const topicBodyMenuItems = [
    {
      id: 1,
      title: 'Edit Topic',
      disabled: !teamMemberExists,
      key: 'edit_item',
      onClick: () => {
        onEditTopic();
      },
    },
    {
      id: 2,
      key: 'archive_item',
      title: `${isArchived ? 'Unarchive' : 'Archive'} Topic`,
      onClick: () => {
        onArchiveTopicMessage();
      },
    },
  ];

  const dmBodyMenuItems = [
    {
      id: 1,
      key: 'archive_item',
      title: `${isArchived ? 'Unarchive' : 'Archive'} Message`,
      onClick: () => {
        onArchiveTopicMessage();
      },
    },
  ];

  return (
    <div className="h-full flex flex-col">
      <ChatBodyHeader
        openChatId={openChatId}
        topicName={topicName}
        receipientDetails={receipientDetails}
        onChatBack={onChatBack}
        onEditTopic={onEditTopic}
        onArchiveTopicMessage={onArchiveTopicMessage}
        isArchived={isArchived}
        openChatType={openChatType}
        menuItems={
          openChatType === 'topic' || openChatType === 'archivedTopic'
            ? topicBodyMenuItems
            : dmBodyMenuItems
        }
      />
      {openChatType === 'topic' && isUtilityPresent() && (
        <div className="flex flex-col px-6">
          <div
            className="flex items-center justify-center p-1 cursor-pointer"
            onClick={onOpenUtility}
          >
            <div className="text-brand-blue-500 text-xs font-medium">
              View {getSingularUtility(utility)}
            </div>
            <IconButton>
              <Launch
                className="text-brand-blue-500"
                sx={{
                  fontSize: '14px',
                }}
              />
            </IconButton>
          </div>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              borderColor: '#BABAAE',
              opacity: '20%',
            }}
          />
        </div>
      )}
      <div className="flex w-full flex-col overflow-y-auto flex-1 mb-2">
        <div className="flex flex-1 pt-6 pb-1">
          <div className="flex flex-col gap-3 mt-auto items-center w-full">
            <MessageIcon
              sx={{
                height: '24px',
                width: '24px',
              }}
            />
            <div className="flex items-center text-sm justify-center text-grey-200">
              You created the chat{' '}
              {dayjs(createdDate).isYesterday() && 'yesterday'}
              {dayjs(createdDate).isToday() && 'today'}
              {!dayjs(createdDate).isYesterday() &&
                'on' &&
                !dayjs(createdDate).isToday() &&
                dayjs(createdDate).year() === dayjs().year() &&
                dayjs(createdDate).format('DD MMMM').toUpperCase()}
              {!dayjs(createdDate).isYesterday() &&
                dayjs(createdDate).year() !== dayjs().year() &&
                dayjs(createdDate).format('DD MMMM YYYY').toUpperCase()}
            </div>
          </div>
        </div>
        <div className="px-6">
          <ChatBubbleGroup
            currentUserId={userId}
            messageList={chatMessages}
            receipientDetails={receipientDetails}
          />
        </div>
      </div>
      {!isArchived && (
        <div>
          <ChatInputField
            onSendMessage={(
              messageText,
              callback,
              storyContext = '',
              id = ''
            ) =>
              onSendMessage(
                messageText,
                callback,
                LAST_ONE_HOUR_MESSAGES,
                storyContext,
                id
              )
            }
            receipientDetails={receipientDetails}
          />
        </div>
      )}
    </div>
  );
};

export default ChatBody;
