import create from 'zustand';

const useMyStudentStore = create((set) => ({
  myStudents: [],
  setMyStudents: (myStudents) =>
    set(() => ({
      myStudents,
    })),
  isFetching: false,
  setIsFetching: (isFetching) => set(() => ({ isFetching })),
  isAddStudent: false,
  setIsAddStudent: (isAddStudent) => set(() => ({ isAddStudent })),
  isRemoving: false,
  setIsRemoving: (isRemoving) => set(() => ({ isRemoving })),
  removeStudentId: null,
  setIsRemoveStudentId: (removeStudentId) => set(() => ({ removeStudentId })),
  isDeletingSnackbar: false,
  setIsDeletingSnackbar: (isDeletingSnackbar) =>
    set(() => ({ isDeletingSnackbar })),
  activePricingPageCustomerId: '',
  setActivePricingPageCustomerId: (activePricingPageCustomerId) =>
    set(() => ({ activePricingPageCustomerId })),
}));

export default useMyStudentStore;
