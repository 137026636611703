import useMyTeamStore from '../../stores/MyTeamStore';
import TagsInput from './TagsInput';
import Tooltip from './Tooltip';

export default function SelectTeamMembers({
  label = '',
  placeholder = '',
  tooltipTitle = '',
  options = [],
  menuListTitle = '',
  value = [],
  multiple = true,
  isParent = false,
  ...props
}) {
  const myTeamStore = useMyTeamStore();
  return (
    <Tooltip title={tooltipTitle} placement="right">
      <div>
        <TagsInput
          multiple={multiple}
          label={label}
          placeholder={placeholder}
          menuListCount={5}
          options={options}
          value={value}
          isViewAll={true}
          isParent={isParent}
          menuListTitle={menuListTitle}
          isSelectTeamMember={true}
          onAddTeamMember={() => {
            myTeamStore.setIsAddingTeamMember(true);
          }}
          sx={{
            '.MuiFormControl-root': {
              width: '100%',
              '.MuiOutlinedInput-notchedOutline': {
                span: {
                  margin: '0px 10px',
                },
              },
              '.MuiAutocomplete-endAdornment': {
                '.MuiAutocomplete-popupIndicator': {
                  display: 'none',
                },
              },
              '.MuiAutocomplete-input': {
                padding: '10px',
              },
            },
          }}
          {...props}
        />
      </div>
    </Tooltip>
  );
}
