/**
 * 
 * @param {string} licenseType 
 * @returns 
 */
export const getUserAccess = (licenseType) => {
    const accessRules = {
        freemium: {
            niches: 1,
            activities: 1,
            honors: 1,
            courses: 1,
            tests: 1,
            gpa: 1,
            essays: 1,
            colleges: 1,
        },
        basic_monthly: {
            niches: Infinity,
            activities: Infinity,
            honors: Infinity,
            courses: Infinity,
            tests: Infinity,
            gpa: Infinity,
            essays: 1,
            colleges: 1,
        },
        basic_yearly: {
            niches: Infinity,
            activities: Infinity,
            honors: Infinity,
            courses: Infinity,
            tests: Infinity,
            gpa: Infinity,
            essays: 1,
            colleges: 1,
        },
        premium_monthly: {
            niches: Infinity,
            activities: Infinity,
            honors: Infinity,
            courses: Infinity,
            tests: Infinity,
            gpa: Infinity,
            essays: Infinity,
            colleges: Infinity,
        },
        premium_yearly: {
            niches: Infinity,
            activities: Infinity,
            honors: Infinity,
            courses: Infinity,
            tests: Infinity,
            gpa: Infinity,
            essays: Infinity,
            colleges: Infinity,
        },
        unlimited: {
            niches: Infinity,
            activities: Infinity,
            honors: Infinity,
            courses: Infinity,
            tests: Infinity,
            gpa: Infinity,
            essays: Infinity,
            colleges: Infinity,
        },
    };
    return accessRules[licenseType] || accessRules.freemium; // default to freemium if licenseType is undefined
};