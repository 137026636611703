import { create } from 'zustand';

const useTeamStore = create((set) => ({
  teams: [],
  teamsById: {},
  setTeams: (teams) =>
    set(() => ({
      teams,
      teamsById:
        teams.length > 0
          ? teams.reduce((acc, team) => ({ ...acc, [team.id]: team }))
          : {},
    })),

  selectedTeam: {},
  setSelectedTeam: (selectedTeam) => set(() => ({ selectedTeam })),

  isRemovingTeamMember: false,
  setIsRemovingTeamMember: (isRemovingTeamMember) =>
    set(() => ({ isRemovingTeamMember })),

  removeTeamMemberId: '',
  setIsRemoveTeamMemberId: (removeTeamMemberId) =>
    set(() => ({ removeTeamMemberId })),

  isLeavingTeam: false,
  setIsLeavingTeam: (isLeavingTeam) => set(() => ({ isLeavingTeam })),

  leaveTeamId: '',
  setLeaveTeamId: (leaveTeamId) => set(() => ({ leaveTeamId })),

  isEditingTeamName: false,
  setIsEditingTeamName: (isEditingTeamName) =>
    set(() => ({ isEditingTeamName })),

  editTeamNameData: {
    id: '',
    teamName: '',
  },
  setEditTeamNameData: (editTeamNameData) => set(() => ({ editTeamNameData })),
}));

export default useTeamStore;
