import DeleteDialog from '../shared/DeleteDialog';

const DeleteEssayDialog = ({ ...args }) => {
  return (
    <DeleteDialog title="Delete Essay" {...args}>
      <div className="text-layer-blur font-medium">
        Are you sure you want to delete this essay?
      </div>
      <div className="text-lead-500 text-[13px]">
        Your team will lose access to this Essay.
      </div>
    </DeleteDialog>
  );
};
export default DeleteEssayDialog;
