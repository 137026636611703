import { Divider } from '@mui/material';
import { CollegeAutoComplete } from './CollegeAutocomplete';

const AddCollege = ({
  collegeList = [],
  selectedDropdownValue = [],
  onClose = () => {},
  onChange = () => {},
}) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="text-xs text-lead-500">
        Add a College that you are interested in applying to. If your selected
        college is not listed, send a message to Team Universily.
      </div>
      <CollegeAutoComplete
        options={collegeList}
        value={selectedDropdownValue}
        background="white"
        onChange={onChange}
        onClose={onClose}
      />
    </div>
  );
};

export default AddCollege;
