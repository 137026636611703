import { createBrowserRouter, Outlet } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import * as ROUTES from './constants';
import { LicenseGuard } from '../guards/LicenseGuard';
import AdminGuard from '../guards/AdminGuard';
import TeamGuard from '../guards/TeamGuard';
import { MyTeamDetails } from '../pages/myTeamDetails/MyTeamDetails';
import AppContainer from '../containers/AppContainer';
import AdminLayout from '../layouts/adminLayout';

const AuthGuard = lazy(() => import('../guards/AuthGuard'));
const GuestGuard = lazy(() => import('../guards/GuestGuard'));
const ErrorPage = lazy(() => import('../pages/error-page'));
const SignInPage = lazy(() => import('../pages/auth/signIn/index'));
const SignUpPage = lazy(() => import('../pages/auth/signUp/index'));
const AcceptInvitation = lazy(() =>
  import('../pages/accept-invitation/AcceptInvitation')
);
const DashBoardLayout = lazy(() => import('../layouts/dashboardLayout/index'));
const DashBoardPage = lazy(() => import('../pages/dashboard/index'));
const MyStoryDayZero = lazy(() =>
  import('../components/myStory/MyStoryDayZero')
);
const MyStoryContainer = lazy(() => import('../containers/MyStory.container'));
const ActivitiesPageContainer = lazy(() =>
  import('../containers/ActivitiesPage.container')
);
const HonorsPageContainer = lazy(() =>
  import('../containers/HonorsPage.container')
);
const NichePageContainer = lazy(() =>
  import('../containers/NichePage.container')
);
const TestsPageContainer = lazy(() =>
  import('../containers/TestsPage.container')
);
const GPAPageContainer = lazy(() => import('../containers/GPAPage.container'));
const CoursesPageContainer = lazy(() =>
  import('../containers/CoursesPage.container')
);
const AllActivitiesPageContainer = lazy(() =>
  import('../containers/AllActivitiesPage.container')
);
const MyHomePageContainer = lazy(() =>
  import('../containers/MyHomePage.container')
);

const InitialPageContainer = lazy(() => import('../containers/Initial.container'));
const AllHonorsPageContainer = lazy(() =>
  import('../containers/AllHonorsPage.container')
);
const MyEssayPageContainer = lazy(() =>
  import('../containers/MyEssayPage.container')
);
const ReferAFriendPageContainer = lazy(() =>
  import('../containers/ReferAFriendPage.container')
);
const TeamsPage = lazy(() => import('../containers/TeamsPage'));

const MyHubContainer = lazy(() => import('../containers/MyHub.container'));
const UserLoginDetailsContainer = lazy(() =>
  import('../containers/UserLoginDetails.container')
);
const CollegeDetailsPageContainer = lazy(() =>
  import('../containers/CollegeDetailsPage.container')
);
const UniversilyTemplatesContainer = lazy(() =>
  import('../containers/UniversilyTemplates.container')
);
const CommonEssayContainer = lazy(() =>
  import('../containers/CommonEssay.container')
);
const MyAccountPageContainer = lazy(() =>
  import('../containers/MyAccountPage.container')
);
const MyApplicationsPageContainer = lazy(() =>
  import('../containers/MyApplicationsPage.container')
);
const AcceptRequest = lazy(() =>
  import('../pages/AcceptRequest/AcceptRequest')
);

const MyStoryRoutes = [
  {
    index: true,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyStoryDayZero
          showDropDown={true}
          text="Niches, Activities, Honors, Courses and Tests"
        />
      </Suspense>
    ),
  },
  {
    path: 'activities',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ActivitiesPageContainer />
      </Suspense>
    ),
  },
  {
    path: 'honors',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HonorsPageContainer />
      </Suspense>
    ),
  },
  {
    path: 'niche',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <NichePageContainer />
      </Suspense>
    ),
  },
  {
    path: 'tests',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <TestsPageContainer />
      </Suspense>
    ),
  },
  {
    path: 'gpaScores',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <GPAPageContainer />
      </Suspense>
    ),
  },
  {
    path: 'courses',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CoursesPageContainer />
      </Suspense>
    ),
  },
];

const AccountRoutes = [
  {
    path: 'account',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyAccountPageContainer selectedTab={0} />
      </Suspense>
    ),
  },
  {
    path: 'subscriptions',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyAccountPageContainer selectedTab={1} />
      </Suspense>
    ),
  },
];

const DashboardRoutes = [
  {
    index: true,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <DashBoardPage />
      </Suspense>
    ),
  },
  {
    path: 'myStory',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyStoryContainer />
      </Suspense>
    ),
    children: MyStoryRoutes,
  },
  {
    path: 'all-activities',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AllActivitiesPageContainer />
      </Suspense>
    ),
  },
  {
    path: 'all-honors',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AllHonorsPageContainer />
      </Suspense>
    ),
  },
  {
    path: 'myEssay',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyEssayPageContainer />
      </Suspense>
    ),
  },
  {
    path: 'myColleges',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyApplicationsPageContainer />
      </Suspense>
    ),
  },
  {
    path: 'myHub',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyHubContainer />
      </Suspense>
    ),
  },
];

const AppRoutes = createBrowserRouter([
  {
    path: ROUTES.SIGN_IN,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <GuestGuard>
          <SignInPage />
        </GuestGuard>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.SIGN_UP,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <GuestGuard>
          <SignUpPage />
        </GuestGuard>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.ACCEPT_TEAM_INVITE,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AcceptInvitation />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.ACCEPT_REQUEST,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AcceptRequest />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.ROOT,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AuthGuard>
          <AppContainer />
          <DashBoardLayout />
        </AuthGuard>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <InitialPageContainer />
          </Suspense>
        ),
      },
      {
        path: 'myHome',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <MyHomePageContainer />
          </Suspense>
        ),
      },
      {
        path: 'referAFriend',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ReferAFriendPageContainer />
          </Suspense>
        ),
      },
      {
        path: 'myTeam',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <TeamsPage />
          </Suspense>
        ),
      },
      {
        path: 'myTeam/:teamId',
        element: <MyTeamDetails />,
      },
      {
        path: 'myAccount',
        children: AccountRoutes,
      },
      {
        path: ':teamID',
        element: (
          <TeamGuard>
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          </TeamGuard>
        ),
        children: DashboardRoutes,
      },
    ],
  },
  // Admin routes remain unchanged and don't need teamID
  {
    path: ROUTES.ADMIN_ROOT,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AuthGuard>
          <AppContainer />
          <AdminLayout />
        </AuthGuard>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'userLoginDetail',
        element: (
          <AdminGuard>
            <Suspense fallback={<div>Loading...</div>}>
              <UserLoginDetailsContainer />
            </Suspense>
          </AdminGuard>
        ),
      },
      {
        path: 'colleges',
        element: (
          <AdminGuard>
            <Suspense fallback={<div>Loading...</div>}>
              <CollegeDetailsPageContainer />
            </Suspense>
          </AdminGuard>
        ),
      },
      {
        path: 'universilyTemplates',
        element: (
          <AdminGuard>
            <Suspense fallback={<div>Loading...</div>}>
              <UniversilyTemplatesContainer />
            </Suspense>
          </AdminGuard>
        ),
      },
      {
        path: 'commonEssays',
        element: (
          <AdminGuard>
            <Suspense fallback={<div>Loading...</div>}>
              <CommonEssayContainer />
            </Suspense>
          </AdminGuard>
        ),
      },
    ],
  },
  {
    path: '/*',
    element: <h1>Nothing here</h1>,
  },
]);

export { AppRoutes };
