import { create } from 'zustand';

export const useMyStoryStore = create((set) => ({
  activeStoryType: '',
  setActiveStoryType: (type) => set({ activeStoryType: type }),
  storySlideOut: false,
  setStorySlideOut: (value) => set({ storySlideOut: value }),
  activeSlideOutTabIndex: 0,
  setActiveSlideOutTabIndex: (index) => set({ activeSlideOutTabIndex: index }),
}));
