import { create } from 'zustand';
import { checkForPremiumAccess } from '../utils/checkForPremiumAccess';
import { canCreateNewUtilities } from '../utils/canCreateNewUtilities';

const useProfileStore = create((set) => ({
  profile: {},
  setProfile: (profile) => set(() => ({ profile })),
  isParent: false,
  hasPremiumAccess: false, // check if the user has premium access
  setHasPremiumAccess: (profile) =>
    set(() => ({
      hasPremiumAccess: checkForPremiumAccess(
        profile?.licenseData?.subscriptionStatus
      ),
    })),
  canCreateNewUtilities: false, // canCreateNewUtilities(profile)
  setCanCreateNewUtilities: (profile) =>
    set(() => ({
      canCreateNewUtilities: canCreateNewUtilities(profile),
    })), // Check if the user can create new utilities
  isSubscriptionActive: false,
  setIsSubscriptionActive: (isSubscriptionActive) =>
    set(() => ({ isSubscriptionActive })),
  profileLogin: {},
  setProfileLogin: (profileLogin) => set(() => ({ profileLogin })),
  subscriptions: [],
  setSubscriptions: (subscriptions) => set(() => ({ subscriptions })),
  isEditingProfile: false,
  setIsEditingProfile: (isEditingProfile) => set(() => ({ isEditingProfile })),
  editProfileId: null,
  setEditProfileId: (editProfileId) => set(() => ({ editProfileId })),
  isChangePassword: false,
  setIsChangePassword: (isChangePassword) => set(() => ({ isChangePassword })),
  snackBarStatus: {
    open: false,
    message: '',
  },
  setSnackBarStatus: (snackBarStatus) => set(() => ({ snackBarStatus })),
  setIsParent: (isParent) => set(() => ({ isParent })),
  showOnboarding: false,
  setShowOnboarding: (showOnboarding) => set(() => ({ showOnboarding })),
}));

export default useProfileStore;
