import { addStoryItemTypes } from '../constants/other';
import useInviteAFriendStore from '../stores/InviteAFriendStore';
import InviteAFriendPopup from '../components/referAFriend/InviteAFriendPopup';
import { addStoryItem } from '../firebase/services/addServices';
import useProfileStore from '../stores/ProfileStore';
import { getProfileDataByEmail } from '../firebase/services/user';
import config from '../utils/urlConfig';
import { axiosPost } from '../firebase/axios';
import { ApiRoutes } from '../firebase/apis';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { useState } from 'react';
import { getReferralData } from '../firebase/services/getServices';
import { userTypeKeywords } from '../constants/keywords';

export const ReferAFriendContainer = () => {
  const inviteStore = useInviteAFriendStore();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState({
    open: false,
    message: '',
  });
  const [disabled, setDisabled] = useState(false);
  const profileStore = useProfileStore();

  const sendInviteAFriend = async (teamMember) => {
    const { firstName, lastName, id, uid } = profileStore.profile;
    const profileData = await getProfileDataByEmail(teamMember.email);
    let eventAttributes = {};
    if (!profileData) {
      eventAttributes = {
        senderId: uid,
        senderFirstName: firstName,
        senderLastName: lastName,
        receiverEmail: teamMember.email,
        receiverFirstName: teamMember.firstName,
        receiverLastName: teamMember.lastName,
      };
    } else {
      setSuccessSnackbarOpen({
        open: true,
        message: ` ${teamMember.email}  already exists `,
      });
    }
    let result;
    if (!profileData) {
      result = await axiosPost(ApiRoutes?.REFERRAL, eventAttributes);
    }
    if (result?.data?.status === 'success') {
      setSuccessSnackbarOpen({
        open: true,
        message: `Invite sent to ${teamMember.firstName} ${teamMember.lastName}`,
      });
    }
    return result;
  };
  const teamMemberActions = async (isEditing, teamMember) => {
    const referalAlreadySent = await getReferralData(teamMember?.email);
    if (!referalAlreadySent) {
      if (teamMember.firstName && teamMember.lastName && teamMember.email) {
        if (
          !inviteStore.invitedMembers.some(
            (item) => item.email === teamMember.email
          )
        ) {
          const result = await sendInviteAFriend(teamMember);
          if (result?.data?.status === 'success') {
            await addStoryItem(
              { ...teamMember, refereeId: profileStore?.profile?.uid },
              addStoryItemTypes.REFERRAL
            );
          }
          inviteStore.setIsInvitingAFriend(false);
          setDisabled(false);
        } else {
          setSuccessSnackbarOpen({
            open: true,
            message: `${teamMember.email} has already been referred`,
          });
          inviteStore.setIsInvitingAFriend(false);
        }
      }
    } else if (referalAlreadySent.refereeId === profileStore.profile?.uid) {
      setSuccessSnackbarOpen({
        open: true,
        message: `You have already invited ${teamMember.email}`,
      });
      inviteStore.setIsInvitingAFriend(false);
    } else {
      setSuccessSnackbarOpen({
        open: true,
        message: `${teamMember.email} has already been referred`,
      });
      inviteStore.setIsInvitingAFriend(false);
    }
    setDisabled(false);
  };
  const isParent =
    profileStore?.profile?.profileType !== userTypeKeywords.STUDENT &&
    profileStore?.profile?.profileType !== userTypeKeywords.ADMINISTRATOR;
  return (
    <>
      <SuccessSnackbar
        message={successSnackbarOpen.message}
        open={successSnackbarOpen.open}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessSnackbarOpen({
            open: false,
            message: '',
          });
        }}
      />
      <InviteAFriendPopup
        disabled={disabled}
        setDisabled={setDisabled}
        open={inviteStore.isInvitingAFriend}
        onCancel={() => {
          inviteStore.setIsInvitingAFriend(false);
        }}
        isParent={isParent}
        onContinue={(inviteMember) => {
          teamMemberActions(inviteStore.isInvitingAFriend, inviteMember);
        }}
      />
    </>
  );
};
