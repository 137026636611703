import create from 'zustand';

const useMyTeamStore = create((set) => ({
  myTeam: [],
  myTeamById: {},
  setMyTeam: (myTeam) =>
    set(() => ({
      myTeam,
      myTeamById: myTeam.reduce(
        (acc, team) => ({ ...acc, [team.id]: team }),
        {}
      ),
    })),
  isFetchingTeamData: false,
  setIsFetchingTeamData: (isFetchingTeamData) =>
    set(() => ({ isFetchingTeamData })),
  isAddingTeamMember: false,
  setIsAddingTeamMember: (isAddingTeamMember) =>
    set(() => ({ isAddingTeamMember })),
  teamMember: null,
  setTeamMember: (teamMember) => set(() => ({ teamMember })),
  isEditingTeamMember: false,
  setIsEditingTeamMember: (isEditingTeamMember) =>
    set(() => ({ isEditingTeamMember })),
  editTeamMemberId: null,
  setEditTeamMemberId: (editTeamMemberId) => set(() => ({ editTeamMemberId })),
  isRemovingTeamMember: false,
  setIsRemovingTeamMember: (isRemovingTeamMember) =>
    set(() => ({ isRemovingTeamMember })),
  removeTeamMemberId: null,
  setIsRemoveTeamMemberId: (removeTeamMemberId) =>
    set(() => ({ removeTeamMemberId })),
  IsDeletingSnackbar: false,
  setIsDeletingSnackbar: (IsDeletingSnackbar) =>
    set(() => ({ IsDeletingSnackbar })),
}));

export default useMyTeamStore;
