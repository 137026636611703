import create from 'zustand';

const useTestStore = create((set) => ({
  testTypes: [],
  testTypesById: {},
  setTestTypes: (testTypes) =>
    set(() => ({
      testTypes,
      testTypesById: testTypes.reduce(
        (acc, test) => ({
          ...acc,
          [test.id]: test,
        }),
        {}
      ),
    })),
  testSubjectsSAT: [],
  testSubjectsSATById: {},
  setTestSubjectsSAT: (testSubjectsSAT) =>
    set(() => ({
      testSubjectsSAT,
    })),
  testSubjectsACT: [],
  testSubjectsACTById: {},
  setTestSubjectsACT: (testSubjectsACT) =>
    set(() => ({
      testSubjectsACT,
    })),
  testScores: [],
  setTestScores: (testScores) => set(() => ({ testScores })),
  testCount: null,
  setTestCount: (testCount) => set(() => ({ testCount })),
  testTargets: [],
  setTestTargets: (testTargets) => set(() => ({ testTargets })),
  isFetchingScores: false,
  setIsFetchingScores: (isFetchingScores) => set(() => ({ isFetchingScores })),
  isAddingSATScore: false,
  setIsAddingSATScore: (isAddingSATScore) => set(() => ({ isAddingSATScore })),
  isEditingSATScore: false,
  setIsEditingSATScore: (isEditingSATScore) =>
    set(() => ({ isEditingSATScore })),
  isEditingACTScore: false,
  setIsEditingACTScore: (isEditingACTScore) =>
    set(() => ({ isEditingACTScore })),
  editScoreId: null,
  setEditScoreId: (editScoreId) => set(() => ({ editScoreId })),
  isEditingSATTarget: false,
  setIsEditingSATTarget: (isEditingSATTarget) =>
    set(() => ({ isEditingSATTarget })),
  isEditingACTTarget: false,
  setIsEditingACTTarget: (isEditingACTTarget) =>
    set(() => ({ isEditingACTTarget })),
  editTargetId: null,
  setEditTargetId: (editTargetId) => set(() => ({ editTargetId })),
  isAddingSATTarget: false,
  setIsAddingSATTarget: (isAddingSATTarget) =>
    set(() => ({ isAddingSATTarget })),
  isAddingACTScore: false,
  setIsAddingACTScore: (isAddingACTScore) => set(() => ({ isAddingACTScore })),
  isAddingACTTarget: false,
  setIsAddingACTTarget: (isAddingACTTarget) =>
    set(() => ({ isAddingACTTarget })),
  isDeletingScore: false,
  setIsDeletingScore: (isDeletingScore) => set(() => ({ isDeletingScore })),
  isDeletingDate: false,
  setIsDeletingDate: (isDeletingDate) => set(() => ({ isDeletingDate })),
  deleteScoreId: null,
  setDeleteScoreId: (deleteScoreId) => set(() => ({ deleteScoreId })),
  isDeletingTarget: false,
  setIsDeletingTarget: (isDeletingTarget) => set(() => ({ isDeletingTarget })),
  isDeletingSnackbar: false,
  setIsDeletingSnackbar: (isDeletingSnackbar) =>
    set(() => ({ isDeletingSnackbar })),
  deleteTargetId: null,
  setDeleteTargetId: (deleteTargetId) => set(() => ({ deleteTargetId })),

  // Used for slideouts
  successSnackbarMessage: '',
  setSuccessSnackbarMessage: (successSnackbarMessage) =>
    set(() => ({ successSnackbarMessage })),
}));

export default useTestStore;
