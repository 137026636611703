import { Link, Button } from "@mui/material";
import SVG from "react-inlinesvg";

import * as s from "./style.js";
import {
  Logo,
  Toggle,
  ChatIcon,
  NotificationIcon,
} from "../../../assets/index.js";

const Header = ({
  isSideBarOpen,
  setIsSideBarOpen,
  setIsChatOpen,
  isChatOpen,
}) => {
  return (
    <div>
      <s.HeaderMain>
        <s.LeftHeader>
          <Button className="pr-icon" onClick={() => setIsSideBarOpen(true)}>
            <SVG src={Toggle} />
          </Button>
          <Link href="#" className="logo-header">
            <SVG src={Logo} />
          </Link>
        </s.LeftHeader>
        <s.RightHeader>
          <s.ButtonRightHeader>
            <s.CustomIconHeader>
              <s.CustomIconButton
                className="pr-icon hover-icon-main"
                onClick={() => setIsChatOpen(!isChatOpen)}
              >
                <SVG src={ChatIcon} />
              </s.CustomIconButton>
            </s.CustomIconHeader>
            <s.CustomIconButton className="hover-icon-main">
              <SVG src={NotificationIcon} />
            </s.CustomIconButton>
          </s.ButtonRightHeader>
        </s.RightHeader>
      </s.HeaderMain>
    </div>
  );
};

export default Header;
