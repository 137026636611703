import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
// import { ReactComponent as CommonAppTag } from '../../assets/svgs/CommonAppLogo.svg';
import { AddCircle } from '@mui/icons-material';
import Button from './Button';
import { TextField, InputAdornment, Avatar } from '@mui/material';

const TagsInput = ({
  label = 'Tag Type',
  placeholder = 'Art, Dance, etc.',
  menuListCount = 5,
  menuListTitle = '',
  options = [],
  uniList = [],
  isSelectTeamMember = false,
  onAddTeamMember = () => {},
  isViewAll = false,
  isParent = false,
  ...props
}) => {
  const [isMenuListOpen, setIsMenuListOpen] = useState(false);
  const [initialArr, setInitialArr] = useState([]);
  const [menuListElementCount, setMenuListElementCount] =
    useState(menuListCount);
  useEffect(() => {
    !props.inputValue || props.inputValue === ''
      ? setIsMenuListOpen(false)
      : setIsMenuListOpen(true);
  }, [props.inputValue]);

  useEffect(() => {
    setInitialArr(options?.slice(0, menuListElementCount).concat(uniList));
    // eslint-disable-next-line
  }, [menuListElementCount]);

  const selectedData = props.value;

  return (
    <Autocomplete
      options={initialArr}
      getOptionLabel={(option) => {
        return option?.photoURL ? (
          <div className="flex items-center gap-2">
            <Avatar
              src={option?.photoURL}
              sx={{ height: '16px', width: '16px' }}
            />
            {option?.label}
          </div>
        ) : (
          option.label
        );
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      disableCloseOnSelect={true}
      PaperComponent={({ children }) => (
        <div
          className="mt-1 bg-white border border-[#F4F4F4] rounded-lg shadow-1lg"
          onMouseDown={(e) => e.preventDefault()}
        >
          {children}
        </div>
      )}
      groupBy={(option) => option.type}
      renderGroup={(params) =>
        !isMenuListOpen ? (
          <li className="flex flex-col gap-3" key={params.group}>
            {params.group && !isSelectTeamMember && (
              <div className="text-xs text-[#22252D] flex items-center gap-1 opacity-50">
                University of California Types
              </div>
            )}
            {!params.group && (
              <>
                <li className="flex gap-2 flex-wrap">{params.children}</li>
                {initialArr.length >= menuListCount && isViewAll && (
                  <div
                    className="text-sm font-medium underline-offset-1 underline cursor-pointer inline-flex text-lead-500 hover:text-grey-900"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      menuListElementCount === options.length
                        ? setMenuListElementCount(menuListCount)
                        : setMenuListElementCount(options.length);
                    }}
                  >
                    View{' '}
                    {menuListElementCount === options.length ? 'Less' : 'All'}
                  </div>
                )}
              </>
            )}
            {!isSelectTeamMember && params.group && (
              <li className="flex gap-2 flex-wrap">{params.children}</li>
            )}
          </li>
        ) : (
          <li className="flex flex-col gap-2 flex-wrap">
            {params.group && (
              <div className="text-xs text-grey-900 flex items-center gap-1 opacity-50">
                University of California Types
              </div>
            )}
            <li className="flex gap-2 flex-col flex-wrap">{params.children}</li>
          </li>
        )
      }
      renderOption={(props, option) => (
        <li {...props} className="flex gap-2" key={option.id}>
          <div
            className={`font-medium py-2 px-3 text-xs rounded-2xl cursor-pointer ${
              option?.type !== 'UC'
                ? selectedData?.length > 0 &&
                  selectedData?.some((opt) => opt.id === option.id)
                  ? 'bg-[#E9F0FF] text-brand-blue-500 flex'
                  : 'bg-[#EDEDEE] text-grey-900 hover:bg-[#E4E4E5] flex'
                : ''
            }
              ${
                option?.type === 'UC' &&
                (selectedData &&
                selectedData.some((opt) => opt.id === option.id)
                  ? 'bg-brand-blue-25 border border-brand-blue-100 text-brand-blue-500'
                  : 'border border-[#EDEDEE] text-grey-900')
              }  
                `}
          >
            {isSelectTeamMember &&
              (option?.photoURL ? (
                <Avatar
                  src={option?.photoURL}
                  sx={{
                    height: '16px',
                    width: '16px',
                  }}
                  className=" border-white border mr-2"
                />
              ) : (
                <span className="py-px px-1 rounded-full border-white border mr-2">
                  {option?.label?.charAt(0)}
                </span>
              ))}
            {option.label}
          </div>
          {isMenuListOpen ? (
            <div className="text-xs text-grey-900 flex items-center opacity-50">
              {option.tagType}
            </div>
          ) : (
            ''
          )}
        </li>
      )}
      noOptionsText={
        isSelectTeamMember ? (
          <div>
            <div className="text-xs text-[#22252D] flex items-center gap-1 opacity-50">
              {menuListTitle}
            </div>
            <Button
              variant="text"
              startIcon={<AddCircle />}
              onClick={onAddTeamMember}
            >
              Add Team Member
            </Button>
          </div>
        ) : (
          'No Options'
        )
      }
      ListboxComponent={(props) => (
        <div className="p-3 flex flex-col gap-3 h-full max-h-60 overflow-auto">
          {!isMenuListOpen && (
            <div className="text-xs text-[#22252D] flex items-center gap-1 opacity-50">
              {/* <CommonAppTag className="h-5" /> Tags (Most Selected Tags) */}
              {menuListTitle}
            </div>
          )}
          <div className="flex flex-col gap-4">
            {isMenuListOpen ? (
              <>
                {isSelectTeamMember && (
                  <div className="text-xs text-[#22252D] flex items-center gap-1 opacity-50">
                    {menuListTitle}
                  </div>
                )}
                <ul {...props} className="flex flex-col p-0 gap-2" />
              </>
            ) : (
              <>
                <ul
                  {...props}
                  className="flex gap-4 flex-col flex-wrap w-9/12"
                />
                {isSelectTeamMember && !isParent && (
                  <div>
                    <Button
                      variant="text"
                      startIcon={<AddCircle />}
                      onClick={onAddTeamMember}
                      className="mt-1"
                    >
                      Add Team Member
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            fullWidth
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start" />
                  {params.InputProps?.startAdornment}
                </>
              ),
            }}
          />
        );
      }}
      {...props}
    />
  );
};

export default TagsInput;
