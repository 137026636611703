import DeleteDialog from '../shared/DeleteDialog';

const RemoveStudentDialog = ({ titleText, bodyText, buttonText, ...args }) => {
  return (
    <DeleteDialog
      buttonText={buttonText}
      title={titleText ? titleText : 'Remove Student'}
      {...args}
      maxWidth="xs"
    >
      <div className="font-medium text-layer-blur">
        Are you sure you want to leave this team?{' '}
      </div>
      <div className="text-sm text-grey-500">
        By leaving this team you’ll no longer to be able to collaborate or
        communicate via Universily, and you’ll lose access to their Story,
        Essays, and Applications.
      </div>
    </DeleteDialog>
  );
};

export default RemoveStudentDialog;
