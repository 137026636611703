import DeleteDialog from '../../shared/DeleteDialog';

const RemoveTeamMemberDialog = ({ ...args }) => {
  return (
    <DeleteDialog title="Remove Team Member" {...args} maxWidth="xs">
      <div className="font-medium text-layer-blur">
        Are you sure you want to remove this team member?
      </div>
      <div className="text-sm text-grey-500">
        By removing them you’ll no longer be able to collaborate or communicate
        via Universily, and they’ll lose access to Your Story, Essays, and
        Applications.
      </div>
    </DeleteDialog>
  );
};

export default RemoveTeamMemberDialog;
