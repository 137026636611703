import { userTypeKeywords } from '../constants/keywords';

export const UTILITY_TYPES = {
  Activities: 'Activities',
  Honors: 'Honors',
  Courses: 'Courses',
  Test: 'Test',
  GPA: 'GPA',
  Niche: 'Niche',
  Applications: 'Applications',
  Essay: 'Essay',
};
export const INVITE_STATUS = {
  REGISTERED: 'REGISTERED',
  NOT_REGISTERED: 'NOT_REGISTERED',
  JOINED: 'JOINED',
  REQUESTED_TO_JOIN: 'REQUESTED_TO_JOIN',
};

export const userTypesWithHubAccess = [
  userTypeKeywords.FAMILY,
  userTypeKeywords.PRIVATE_COUNSELOR,
  userTypeKeywords.SCHOOL_COUNSELOR,
  userTypeKeywords.TEACHER,
  userTypeKeywords.UNIVERSILY,
];

const UNI_EMAIL = 'askuni@universily.com';
const TEAM_UNIVERSILY_EMAIL = 'team@universily.com';
export const TEAM_UNIVERSILY_DETAILS = {
  id: 'n1ukevM554mxyb3CwZOB',
  email: 'team@universily.com',
  label: 'Team Universily',
  photoURL:
    'https://firebasestorage.googleapis.com/v0/b/universily-v2-dev.appspot.com/o/users%2FSZgy7xMqrxVRU7nygHUP2TRHTvA2%2Fprofile-image.jpeg?alt=media&token=643c3dde-d21a-42e3-9390-69089c0c926b',
  uid: 'SZgy7xMqrxVRU7nygHUP2TRHTvA2',
};
export const ASK_UNI_DETAILS = {
  id: '7msEgbiB3hOin3GfpUw9',
  email: 'askuni@universily.com',
  label: 'Ask Uni',
  photoURL:
    'https://firebasestorage.googleapis.com/v0/b/universily-v2-dev.appspot.com/o/users%2FkKeHVga0VjMNZpxOyzJoGQZP8AE2%2Fprofile-image.jpeg?alt=media&token=0716c4c0-5071-42e3-a7b5-3eae22b543cd',
  uid: 'kKeHVga0VjMNZpxOyzJoGQZP8AE2',
};
export const filterUniAndTeamUniversily = (array) => {
  return array?.filter(
    (item) => item?.email !== UNI_EMAIL && item?.email !== TEAM_UNIVERSILY_EMAIL
  );
};

/** Filter out IDs which do not contain Team Uni ID */
export const filterUniIDs = (array) => {
  return array?.filter(
    (item) =>
      item !== TEAM_UNIVERSILY_DETAILS?.uid &&
      item !== TEAM_UNIVERSILY_DETAILS?.id
  );
};

export const addTeamUniversilyToReviewers = (array) => {
  return array?.concat(TEAM_UNIVERSILY_DETAILS.uid);
};

export const addTeamUniversilyToChatParticipants = (array) => {
  return array?.concat(TEAM_UNIVERSILY_DETAILS.uid);
};
