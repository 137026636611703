import { ReactComponent as HighlightImage } from '../../assets/svgs/Highlight.svg';
import { ReactComponent as SpiralImage } from '../../assets/svgs/Spiral.svg';
import Button from '../shared/Button';
import MyTeamIllustration from '../../assets/svgs/MyTeamIllustration.png';
import AddIcon from '@mui/icons-material/Add';

const MyStudentDayZero = ({ onAddTeamMember = () => {} }) => {
  return (
    <div className="h-full overflow-y-auto flex-col flex gap-6 sm:gap-0 pt-9 px-4 sm:pt-0 sm:flex-row sm:px-24">
      <div className="flex-1 flex flex-col gap-6 sm:gap-8 justify-center">
        <div className="font-bold text-4xl sm:text-5xl font-archivo leading-tight">
          <div className="flex">
            <div className="relative">
              Start by joining a
              <HighlightImage className="absolute top-0 right-0 translate-x-7" />
            </div>
          </div>
          <div className="flex">
            <div className="relative">
              Student's Team
              <SpiralImage className="absolute bottom-0 right-0 translate-y-3 translate-x-7" />
            </div>
          </div>
        </div>
        <div className="text-[#515152] sm:text-lg w-4/5 sm:w-100">
          Students can invite anyone they trust to help navigate the college
          application process. The student’s Team Members can review and comment
          on all of the components of their story and college applications.
        </div>
        <div className="flex">
          <Button startIcon={<AddIcon />} onClick={onAddTeamMember}>
            Add New Team
          </Button>
        </div>
      </div>
      <div className="flex-1 shrink-0 flex items-center py-0 sm:py-20">
        <img
          src={MyTeamIllustration}
          alt="my team illustration"
          className="w-full h-fit sm:h-full object-contain"
        />
      </div>
    </div>
  );
};

export default MyStudentDayZero;
