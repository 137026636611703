import { Tooltip } from '@mui/material';
import React from 'react';
import { INVITE_STATUS } from '../../../utils/utlityTypes';

export const CustomRow = ({
  row,
  rowIndex,
  columns,
  actions,
  profile,
  handleNavigateToTeamDetails,
}) => {
  const isPendingApproval = profile?.teams?.some(
    (team) =>
      team.teamId === row.id &&
      (team.status === INVITE_STATUS.REQUESTED_TO_JOIN ||
        team.status === INVITE_STATUS.REGISTERED)
  );

  return (
    <Tooltip title={isPendingApproval ? 'Pending Approval' : ''} arrow>
      <tr
        onClick={() => {
          if (!isPendingApproval) handleNavigateToTeamDetails(row.id);
        }}
        key={rowIndex}
        className={`border-b text-grey-700 text-sm leading-tight cursor-pointer ${
          isPendingApproval ? 'bg-gray-200 cursor-not-allowed' : ''
        }`}
      >
        {columns.map((column) => (
          <td key={column.key} className="py-3 px-4">
            {row[column.key]}
          </td>
        ))}

        {actions && actions.length > 0 && (
          <td className="py-3 px-4 flex gap-2 justify-end">
            {actions.map((action, actionIndex) => (
              <button
                key={actionIndex}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isPendingApproval) action.handler(row);
                }}
                className={`custom-button-class ${
                  isPendingApproval ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isPendingApproval}
              >
                {action.label(row)}
              </button>
            ))}
          </td>
        )}
      </tr>
    </Tooltip>
  );
};
