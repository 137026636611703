// useRealtimeListener.js
import { useEffect, useState } from 'react';
import { collection, doc, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';

export const useRealtimeListener = (
  collectionName,
  { id = null, field = null, value = null } = {}
) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let unsubscribe;

    try {
      if (id) {
        // Listen to a specific document by ID
        const docRef = doc(db, collectionName, id);
        unsubscribe = onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            setData([{ id: doc.id, ...doc.data() }]);
          } else {
            console.log('No such document!');
            setData([]);
          }
        });
      } else if (field && value) {
        // Listen to documents matching a specific field and value
        const colRef = collection(db, collectionName);
        const q = query(colRef, where(field, '==', value));
        unsubscribe = onSnapshot(q, (snapshot) => {
          const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(newData);
        });
      } else {
        // Listen to the entire collection
        const colRef = collection(db, collectionName);
        unsubscribe = onSnapshot(colRef, (snapshot) => {
          const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(newData);
        });
      }
    } catch (err) {
      setError(err);
    }

    // Cleanup subscription on component unmount
    return () => unsubscribe && unsubscribe();
  }, [collectionName, id, field, value]);

  return { data, error };
};
