import { AvatarGroup } from '@mui/material';

const TeamMemberAvatarGroup = ({ children, handleClick }) => {
  return (
    <AvatarGroup
      onClick={(e) => {
        e.stopPropagation();
        if (handleClick) {
          handleClick();
        }
      }}
      max={3}
      sx={{
        '& .MuiAvatar-root': {
          width: 24,
          height: 24,
          fontSize: 12,
          color: '#494E5B',
          backgroundColor: '#F1F1F2',
          borderColor: '#FFFFFF',
        },
        display: 'flex',
        justifyContent: 'start',
      }}
    >
      {children}
    </AvatarGroup>
  );
};

export default TeamMemberAvatarGroup;
