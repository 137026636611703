import { useState } from 'react';
import Dialog from '../shared/Dialog';
import AddCollege from './AddCollege';
const REGULAR = 7;
const ROLLING = 8;

const AddCollegeDialog = ({
  collegeList = [],
  onContinue = () => {},
  onCancel = () => {},
  ...props
}) => {
  const [selectedDropdownValue, setSelectedDropdownValue] = useState([]);
  const onChange = (event, newValue) => {
    setSelectedDropdownValue(newValue);
  };
  const onClose = () => {
    setSelectedDropdownValue([]);
  };
  return (
    <Dialog
      onContinue={async () => {
        const appRounds =
          selectedDropdownValue[selectedDropdownValue?.length - 1]?.rounds;
        const roundId = appRounds.find(
          (item) => item?.round === REGULAR || item?.round === ROLLING
        )?.id;
        const collegeRound = roundId ? roundId : appRounds[0]?.id;
        const collegeId =
          selectedDropdownValue[selectedDropdownValue?.length - 1]?.collegeId;

        await onContinue(
          appRounds
            ? {
                collegeId,
                round: collegeRound,
              }
            : { collegeId }
        );
        setSelectedDropdownValue([]);
      }}
      onCancel={() => {
        onCancel();
        setSelectedDropdownValue([]);
      }}
      {...props}
    >
      <AddCollege
        collegeList={collegeList}
        selectedDropdownValue={selectedDropdownValue}
        onChange={onChange}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default AddCollegeDialog;
