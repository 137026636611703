import { useState } from 'react';
import { CustomSnackbar } from './CustomSnackbar';

let showSnackbar;

/**
 * @typedef {'success' | 'alert' | 'error' | 'info'} SeverityType
 */

/**
 * @typedef {'top' | 'bottom'} VerticalType
 * @typedef {'left' | 'center' | 'right'} HorizontalType
 */

/**
 * @param {string} message
 * @param {SeverityType} [severity='success']
 * @param {number} [autoHideDuration=5000]
 * @param {string} [actionName='']
 * @param {function} [onAction=() => {}]
 * @param {VerticalType} [vertical='top']
 * @param {HorizontalType} [horizontal='center']
 */
export const GlobalSnackbar = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
    autoHideDuration: 5000,
    actionName: '',
    onAction: () => {},
    vertical: 'top',
    horizontal: 'center',
  });

  /**
   * @param {string} message
   * @param {SeverityType} [severity='success']
   * @param {number} [autoHideDuration=5000]
   * @param {string} [actionName='']
   * @param {function} [onAction=() => {}]
   * @param {VerticalType} [vertical='top']
   * @param {HorizontalType} [horizontal='center']
   */
  const showSnackbarFunc = (
    message,
    severity = 'success',
    autoHideDuration = 5000,
    actionName = '',
    onAction = () => {},
    vertical = 'top',
    horizontal = 'center'
  ) => {
    setSnackbar({
      open: true,
      message,
      severity,
      autoHideDuration,
      actionName,
      onAction,
      vertical,
      horizontal,
    });
  };

  const closeSnackbarFunc = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: '',
      autoHideDuration: 3000,
      actionName: '',
      onAction: () => {},
      vertical: 'top',
      horizontal: 'center',
    });
  };

  showSnackbar = showSnackbarFunc;

  return <CustomSnackbar {...snackbar} onClose={closeSnackbarFunc} />;
};

/**
 * @param {string} message
 * @param {SeverityType} [severity]
 * @param {number} [autoHideDuration]
 * @param {string} [actionName]
 * @param {function} [onAction]
 * @param {VerticalType} [vertical]
 * @param {HorizontalType} [horizontal]
 */
export const openSnackbar = (
  message,
  severity,
  autoHideDuration,
  actionName,
  onAction,
  vertical,
  horizontal
) => {
  showSnackbar(
    message,
    severity,
    autoHideDuration,
    actionName,
    onAction,
    vertical,
    horizontal
  );
};
