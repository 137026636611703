const { default: Dialog } = require('../../components/shared/Dialog');

const AcceptRejectRequest = ({
  open,
  onClose,
  onSubmit,
  title,
  text,
  continueText,
}) => {
  return (
    <Dialog
      open={open}
      onContinue={onSubmit}
      title={title}
      onCancel={onClose}
      continueText={continueText}
    >
      <div className="text-sm">{text}</div>
    </Dialog>
  );
};

export default AcceptRejectRequest;
