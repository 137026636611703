import React from 'react';

export const StudentNotRegistered = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <p className="text-center">
        Your student has not registered yet,
        <br />
        Student details will be available once student registers
      </p>
    </div>
  );
};
