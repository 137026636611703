import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { getSingularUtility } from '../../utils/helper';
import Dialog from '../shared/Dialog';
import SelectOption from '../shared/SelectOption';
import SelectTeamMembers from '../shared/SelectTeamMembers';
import Tooltip from '../shared/Tooltip';

const AddTopicDialog = ({
  topicMembers = [],
  isEdit = false,
  selectedUtilityItems = [],
  teamMemberData = [],
  continueText = 'Add Topic',
  existingTopicData = {},
  onChange = () => {},
  onCancel = () => {},
  onContinue = () => {},
  utilitytTypeItems = [],
  isParent = false,
  selectedStudentID = '',
  selectedStudentName = '',
  ...props
}) => {
  const [topicDetails, setTopicDetails] = useState({
    utilityType: existingTopicData?.utilityType || '',
    utilityItem: existingTopicData?.utilityItem || '',
    selectedTeamMembers: existingTopicData.selectedTeamMembers || [],
    topicName: existingTopicData.topicName || '',
  });
  const [disableItemField, setDisableItemField] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    field: '',
    message: '',
  });

  useEffect(() => {
    const newTopicObj = {
      ...topicDetails,
      selectedTeamMembers: existingTopicData.selectedTeamMembers || [],
      topicName: existingTopicData.topicName || '',
    };
    setTopicDetails(newTopicObj);
    // eslint-disable-next-line
  }, [existingTopicData.topicName]);

  useEffect(() => {
    const newTopicObj = {
      ...topicDetails,
      utilityType: existingTopicData?.utilityType || '',
      utilityItem: existingTopicData.utilityItem || '',
    };
    setTopicDetails(newTopicObj);
    // eslint-disable-next-line
  }, [existingTopicData.utilityType, existingTopicData.utilityItem]);

  useEffect(() => {
    if (isParent && selectedStudentID) {
      const topicName = constructTopicName();
      setTopicDetails({ ...topicDetails, topicName });
    }
    //eslint-disable-next-line
  }, [
    topicDetails?.utilityType,
    topicDetails?.utilityItem,
    selectedUtilityItems?.length,
  ]);

  const constructTopicName = (teamMembers) => {
    const topicName =
      isParent && selectedStudentID
        ? `${getSingularUtility(topicDetails.utilityType)} ${
            utilities.find(
              (utilItem) => utilItem?.id === topicDetails?.utilityItem
            )?.label || 'None'
          } with ${selectedStudentName}`
        : `${getSingularUtility(topicDetails.utilityType)} ${
            utilities.find(
              (utilItem) => utilItem?.id === topicDetails?.utilityItem
            )?.label || 'None'
          } with ${teamMembers?.label}`;

    return topicName;
  };

  useEffect(() => {
    onChange(topicDetails);
    // eslint-disable-next-line
  }, [topicDetails]);

  const utilities = [
    {
      id: '',
      label: 'None',
    },
    ...selectedUtilityItems,
  ];

  const title = (
    <div className="text-sm flex flex-col gap-1">
      <div>
        If you want to Chat about a specific item in Universily, like an
        Activity, Essay, or Application, pick which Type and which Item, and
        Universily will automatically create a Topic Name for you.
      </div>
      <div>
        Then when your Team Member reads your Chat they can jump directly to
        that Item. You can also create your own Topic Name to talk about
        anything else you want.
      </div>
    </div>
  );

  return (
    <Dialog
      isHeight={!isEdit}
      title={isEdit ? 'Edit Topic' : 'Add Topic'}
      continueText={continueText}
      disabled={
        errorMessage.field ||
        errorMessage.message ||
        topicDetails.topicName === '' ||
        topicDetails.utilityType === '' ||
        topicDetails.selectedTeamMembers === ''
          ? true
          : false
      }
      onContinue={async () => {
        if (
          topicDetails?.topicName &&
          topicDetails?.topicName?.trim() !== '' &&
          topicDetails?.utilityType.trim() !== ''
        ) {
          await onContinue();
          setTopicDetails({
            utilityType: '',
            utilityItem: '',
            selectedTeamMembers: existingTopicData.selectedTeamMembers || [],
          });
        } else {
          if (topicDetails?.utilityType.trim() === '') {
            setErrorMessage({
              field: 'utilityType',
              message: 'Please select Type',
            });
          } else if (topicDetails?.topicName?.trim() === '') {
            setErrorMessage({
              field: 'topicName',
              message: 'Please enter Topic name',
            });
          }
        }
      }}
      onCancel={() => {
        setErrorMessage({
          field: '',
          message: '',
        });
        setTopicDetails({
          topicName: '',
          utilityType: '',
          utilityItem: '',
          selectedTeamMembers: existingTopicData.selectedTeamMembers || [],
        });
        onCancel();
        setDisableItemField(false);
      }}
      {...props}
    >
      <div className="flex flex-col gap-8">
        <div className="text-lead-500 text-1sm">
          Add topics to talk about specific niches, activities, honors, courses,
          tests, essays or applications.
        </div>
        {!isEdit && (
          <div className="flex justify-between w-full gap-4">
            <div className="w-6/12">
              <SelectOption
                value={Number(
                  utilitytTypeItems.find(
                    (utility) => utility.label === topicDetails?.utilityType
                  )?.id
                )}
                options={utilitytTypeItems}
                label="Type"
                onChange={(e) => {
                  setTopicDetails({
                    ...topicDetails,
                    utilityType: utilitytTypeItems[e.target.value - 1]?.label,
                    utilityItem: '',
                    topicName: '',
                    selectedTeamMembers: [],
                  });
                  if (e.target.value === 1) {
                    setDisableItemField(true);
                  } else {
                    setDisableItemField(false);
                  }
                }}
              />
            </div>
            <div className="w-6/12">
              <Tooltip
                placement="right-start"
                title={title}
                sx={{
                  '.MuiTooltip-tooltip': {
                    maxWidth: '371px',
                    borderRadius: '8px',
                  },
                }}
              >
                <SelectOption
                  required
                  error={errorMessage?.field !== ''}
                  maxWidth="352px"
                  disabled={disableItemField}
                  value={topicDetails?.utilityItem}
                  options={utilities}
                  label={
                    !disableItemField ? 'Item (optional)' : 'No items available'
                  }
                  onChange={(e) => {
                    setTopicDetails({
                      ...topicDetails,
                      utilityItem: e.target.value,
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )}
        {(!isParent || (isParent && selectedStudentID === '')) && (
          <SelectTeamMembers
            label="Select Team Member"
            multiple={false}
            options={teamMemberData}
            isParent={isParent}
            value={
              topicDetails.selectedTeamMembers?.id
                ? topicDetails.selectedTeamMembers
                : {
                    label: '',
                  }
            }
            menuListTitle="Select your Existing Team Members"
            onChange={(e, teamMembers) => {
              setErrorMessage({
                field: '',
                message: '',
              });
              setTopicDetails({
                ...topicDetails,
                selectedTeamMembers: teamMembers,
                topicName: constructTopicName(teamMembers),
              });
            }}
            tooltipTitle={`You can select or add Team Members with whom you wish to collaborate`}
          />
        )}
        <TextField
          label="Topic Name"
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          value={topicDetails?.topicName}
          onChange={(event) => {
            setErrorMessage({ field: '', message: '' });
            setTopicDetails({
              ...topicDetails,
              topicName: event.target.value,
            });
          }}
          helperText={errorMessage.message}
          error={errorMessage?.field !== ''}
        />
      </div>
    </Dialog>
  );
};

export default AddTopicDialog;
