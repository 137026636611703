import RemoveTeamMemberDialog from '../components/myStory/myTeam/RemoveTeamMemberDialog';
import AddTeamMemberDialog from '../components/shared/AddTeamMemberDialog';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { addStoryItemTypes } from '../constants/other';
import { addStoryItem } from '../firebase/services/addServices';
import { updateStoryItem } from '../firebase/services/updateServices';
import { getProfileDataByEmail } from '../firebase/services/user';
import useMyTeamStore from '../stores/MyTeamStore';
import useNicheStore from '../stores/NicheStore';
import useActivityStore from '../stores/ActivityStore';
import useCourseStore from '../stores/CourseStore';
import useHonorStore from '../stores/HonorStore';
import { deleteStoryItem } from '../firebase/services/deleteServices';
import { useEffect, useState } from 'react';
import { axiosPost } from '../firebase/axios';
import { ApiRoutes } from '../firebase/apis';
import config from '../utils/urlConfig';
import useProfileStore from '../stores/ProfileStore';
import { userTypeKeywords } from '../constants/keywords';

const TeamMemberContainer = () => {
  const myTeamStore = useMyTeamStore();
  const activityStore = useActivityStore();
  const courseStore = useCourseStore();
  const honorStore = useHonorStore();
  const nicheStore = useNicheStore();
  const profileStore = useProfileStore();
  const [disabled, setDisabled] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState({
    open: false,
    message: '',
  });
  const [deleteSnackbar, setDeleteSnackbar] = useState(false);
  const unlinkReviewerFromUtility = (id) => {
    activityStore.activities.map(async (activity) => {
      if (activity?.reviewers.includes(id)) {
        await updateStoryItem(
          {
            ...activity,
            reviewers: activity.reviewers.filter((reviewer) => reviewer !== id),
          },
          addStoryItemTypes.ACTIVITY
        );
      }
    });
    courseStore.courses.map(async (course) => {
      if (course?.reviewers.includes(id)) {
        await updateStoryItem(
          {
            ...course,
            reviewers: course.reviewers.filter((reviewer) => reviewer !== id),
          },
          addStoryItemTypes.COURSE
        );
      }
    });
    honorStore.honors.map(async (honor) => {
      if (honor?.reviewers.includes(id)) {
        await updateStoryItem(
          {
            ...honor,
            reviewers: honor.reviewers.filter((reviewer) => reviewer !== id),
          },
          addStoryItemTypes.HONOR
        );
      }
    });
    nicheStore.niches.map(async (niche) => {
      if (niche?.reviewers.includes(id)) {
        await updateStoryItem(
          {
            ...niche,
            reviewers: niche.reviewers.filter((reviewer) => reviewer !== id),
          },
          addStoryItemTypes.NICHE
        );
      }
    });
  };

  const teamMemberActions = async (isEditing, teamMemberDetails) => {
    const teamMember = {
      ...teamMemberDetails,
      firstName: teamMemberDetails?.firstName.trim(),
      lastName: teamMemberDetails?.lastName.trim(),
      email: teamMemberDetails?.email.trim(),
    };
    if (
      isEditing &&
      teamMember.firstName &&
      teamMember.lastName &&
      teamMember.email &&
      teamMember.role
    ) {
      const member = myTeamStore.myTeam.find(
        (item) => item?.id === myTeamStore.editTeamMemberId
      );
      if (member) {
        await updateStoryItem(
          {
            ...member,
            firstName: teamMember.firstName,
            lastName: teamMember.lastName,
            email: teamMember.email,
            role: teamMember.role,
            revieweeId: profileStore.profile?.uid,
          },
          addStoryItemTypes.REVIEWER
        );
        if (member?.email !== teamMember?.email) {
          await sendInviteToReviewer(teamMember);
        }
        myTeamStore.setIsEditingTeamMember(false);
        setSuccessSnackbarOpen({
          open: true,
          message: 'Team Member Edited Successfully',
        });
        myTeamStore.setEditTeamMemberId(null);
        setDisabled(false);
      }
    }
    if (
      !isEditing &&
      teamMember.firstName &&
      teamMember.lastName &&
      teamMember.email &&
      teamMember.role
    ) {
      if (!myTeamStore.myTeam.some((item) => item.email === teamMember.email)) {
        const profileData = await getProfileDataByEmail(teamMember.email);
        const userExists = profileData ? true : false;
        async function addTeamMember() {
          try {
            const res = await addStoryItem(
              {
                ...teamMember,
                revieweeId: profileStore.profile?.uid,
                isRegistered: userExists ? true : false,
                isAccepted: false,
                studentFirstName: profileStore?.profile?.firstName,
                studentLastName: profileStore?.profile?.lastName,
                isStudentAccepted: true,
                isStudentRegistered: true,
              },
              addStoryItemTypes.REVIEWER
            );
            if (res) {
              setSuccessSnackbarOpen({
                open: true,
                message: `Team Member added. Invite sent to ${teamMember.firstName} ${teamMember.lastName}`,
              });
            }
          } catch (error) {
            setSuccessSnackbarOpen({
              open: true,
              message: `Team member not added. Try again`,
            });
          }
        }

        addTeamMember();

        if (!userExists) {
          await addStoryItem(
            { ...teamMember, refereeId: profileStore?.profile?.uid },
            addStoryItemTypes.REFERRAL
          );
        }

        myTeamStore.setIsAddingTeamMember(false);
      }
      setDisabled(false);
    }
  };

  const sendInviteToReviewer = async (teamMember) => {
    const { firstName, lastName, id } = profileStore.profile;
    const profileData = await getProfileDataByEmail(teamMember.email);
    let params = {};
    if (!profileData) {
      params = {
        reviewerEmail: teamMember.email,
        reviewerRole: teamMember?.role,
        isReviewerInvite: true,
        revieeeId: id,
        revieeeName: firstName + ' ' + lastName,
        templateName: 'reviewer_invite',
        inviteLink: `${config.getSiteBaseUrl}/signUp?reviewerMail=${teamMember.email}&first=${teamMember.firstName}&last=${teamMember.lastName}&role=${teamMember.role}&invitee=${profileStore.profile?.uid}`,
      };
    } else if (
      profileData &&
      profileData?.profileType !== userTypeKeywords.STUDENT
    ) {
      const inviteLink = `${config.getSiteBaseUrl}/acceptInvitation?reviewerMail=${teamMember.email}&role=${teamMember.role}&invitee=${profileStore.profile?.uid}`;
      params = {
        reviewerEmail: teamMember.email,
        reviewerRole: teamMember?.role,
        isReviewerInvite: true,
        revieeeId: id,
        revieeeName: firstName + ' ' + lastName,
        templateName: 'invite_registered_user',
        inviteLink: inviteLink,
      };
    }
    const result = await axiosPost(ApiRoutes?.INVITE_USER, params);
    if (!result.data?.error) {
      setSuccessSnackbarOpen({
        open: true,
        message: `Team Member added. Invite sent to ${teamMember.firstName} ${teamMember.lastName}`,
      });
      return { ...result, userExists: profileData ? true : false };
    } else {
      setSuccessSnackbarOpen({
        open: true,
        message: `Team member not added. Try again`,
      });
    }
  };

  const onDelete = async () => {
    setDeleteSnackbar(true);
    myTeamStore.setIsDeletingSnackbar(true);
  };
  const hardDeleteTeamMember = async () => {
    const id = myTeamStore.removeTeamMemberId;
    if (id) {
      await unlinkReviewerFromUtility(id);
      await deleteStoryItem(id, addStoryItemTypes.REVIEWER);
      myTeamStore.setIsDeletingSnackbar(false);
    }
  };
  useEffect(() => {
    if (!deleteSnackbar) {
      hardDeleteTeamMember();
    }
    //eslint-disable-next-line
  }, [deleteSnackbar]);
  const getTeamsData = (userId) => {
    if (userId) {
      return myTeamStore.myTeam.find(({ id }) => id === userId);
    }
  };

  return (
    <>
      <SuccessSnackbar
        message={successSnackbarOpen.message}
        open={successSnackbarOpen.open}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessSnackbarOpen({
            open: false,
            message: '',
          });
        }}
      />
      <SuccessSnackbar
        open={deleteSnackbar}
        autoHideDuration={6000}
        message="The team member was deleted."
        actionName="Undo"
        onAction={() => {
          myTeamStore.setIsRemoveTeamMemberId(null);
          setDeleteSnackbar(false);
          myTeamStore.setIsDeletingSnackbar(false);
        }}
        onClose={() => {
          setDeleteSnackbar(false);
        }}
      />
      <AddTeamMemberDialog
        existingTeamMembers={myTeamStore.myTeam.map(
          (teamMember) => teamMember.email
        )}
        disabled={disabled}
        setDisabled={setDisabled}
        open={myTeamStore.isAddingTeamMember || myTeamStore.isEditingTeamMember}
        isEditDialog={myTeamStore.isEditingTeamMember}
        onContinue={async (teamMember) => {
          teamMemberActions(myTeamStore.isEditingTeamMember, {
            ...teamMember,
            studentEmail: profileStore?.profile?.email,
          });
        }}
        onCancel={() => {
          setDisabled(false);
          myTeamStore.setIsAddingTeamMember(false);
          myTeamStore.setIsEditingTeamMember(false);
          myTeamStore.setEditTeamMemberId(null);
        }}
        memberData={getTeamsData(myTeamStore.editTeamMemberId)}
      />
      <RemoveTeamMemberDialog
        open={myTeamStore.isRemovingTeamMember}
        onCancel={() => {
          myTeamStore.setIsRemovingTeamMember(false);
          myTeamStore.setIsRemoveTeamMemberId(null);
        }}
        onContinue={async () => {
          await onDelete();
          myTeamStore.setIsRemovingTeamMember(false);
        }}
      />
    </>
  );
};

export default TeamMemberContainer;
