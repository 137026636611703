import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks';
import { openSnackbar } from '../components/shared/GlobalSnackbar/GlobalSnackbar';
import useProfileStore from '../stores/ProfileStore';
import { useEffect } from 'react';

const AdminGuard = ({ children }) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const profile = useProfileStore((state) => state.profile);

  useEffect(() => {
    if (!isLoading) {
      if (user.email !== 'admin@universily.com') {
        // Show a toast notification
        openSnackbar('You do not have access to admin page ', 'error');

        // Redirect to the previous page
        navigate('/myTeam'); // This navigates back to the previous page
        return null;
      }
    }
  }, [isLoading]);
  return children;
};

export default AdminGuard;
