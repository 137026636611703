import React from 'react';
import MyAccountDetails from '../../components/myAccount/MyAccountDetails';
import { StudentNotRegistered } from './StudentNotRegistered';

export const AccountView = ({
  uploadProfilePic,
  profileData,
  isFormOpen,
  setIsFormOpen,
  profileStore,
  linkGoogleAccount,
  isParent,
  addHighSchoolLink,
  onSave,
}) => {
  return profileData?.email ? (
    <MyAccountDetails
      uploadProfilePic={uploadProfilePic}
      profileData={profileData}
      onSave={onSave}
      isFormOpen={isFormOpen}
      setIsFormOpen={setIsFormOpen}
      profileLogin={profileStore?.profileLogin}
      linkGoogleAccount={linkGoogleAccount}
      isParent={isParent}
      addHighSchoolLink={addHighSchoolLink}
    />
  ) : (
    <StudentNotRegistered />
  );
};
