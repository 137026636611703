import { Radio } from '@mui/material';

export const RadioButton = ({
  label = '',
  checked = false,
  logo = '',
  ...props
}) => {
  return (
    <div
      className={`flex p-6 cursor-pointer justify-between rounded-lg border ${
        checked
          ? 'bg-brand-blue-500/10 border-brand-blue-500'
          : 'border-lead-900/23'
      }`}
      {...props}
    >
      <div className="flex gap-3 items-center">
        <div>
          <Radio
            checked={checked}
            name="radio-buttons"
            inputProps={{ 'aria-label': 'Select College' }}
            sx={{
              padding: 0,
            }}
            // {...props}
          />
        </div>
        <div className="font-medium text-grey-800">{label}</div>
      </div>
      <div className="flex shrink-0">{logo}</div>
    </div>
  );
};
