import create from 'zustand';

const useAdminStore = create((set) => ({
  collegeDetails: [],
  setCollegeDetails: (collegeDetails) => set(() => ({ collegeDetails })),
  applicationRequirements: [],
  setApplicationRequirements: (applicationRequirements) =>
    set(() => ({ applicationRequirements })),
  collegeListData: [],
  setCollegeListData: (collegeListData) => set(() => ({ collegeListData })),
  universilyTemplateData: [],
  setUniversilyTemplateData: (universilyTemplateData) =>
    set(() => ({ universilyTemplateData })),
  loginPageData: [],
  setLoginPageData: (loginPageData) => set(() => ({ loginPageData })),
  commonEssaysData: [],
  setCommonEssaysData: (commonEssaysData) => set(() => ({ commonEssaysData })),
  collegeSideBarId: null,
  setCollegeSidebarId: (collegeSideBarId) => set(() => ({ collegeSideBarId })),
  isEditCollegeId: null,
  setIsEditCollegeId: (isEditCollegeId) => set(() => ({ isEditCollegeId })),
  isDeleteCollege: false,
  setIsDeleteCollege: (isDeleteCollege) => set(() => ({ isDeleteCollege })),
  deleteCollegeId: null,
  setDeleteCollegeId: (deleteCollegeId) => set(() => ({ deleteCollegeId })),
  isDeleteRequirement: false,
  setIsDeleteRequirement: (isDeleteRequirement) =>
    set(() => ({ isDeleteRequirement })),
  deleteRequirementId: null,
  setDeleteRequirementId: (deleteRequirementId) =>
    set(() => ({ deleteRequirementId })),
  isAddTemplate: false,
  setIsAddTemplate: (isAddTemplate) => set(() => ({ isAddTemplate })),
  isEditTemplate: false,
  setIsEditTemplate: (isEditTemplate) => set(() => ({ isEditTemplate })),
  editTemplateId: null,
  setEditTemplateId: (editTemplateId) => set(() => ({ editTemplateId })),
  isDeleteTemplate: false,
  setIsDeleteTemplate: (isDeleteTemplate) => set(() => ({ isDeleteTemplate })),
  deleteTemplateId: null,
  setDeleteTemplateId: (deleteTemplateId) => set(() => ({ deleteTemplateId })),
  isAddCollegeDetail: false,
  setIsAddCollegeDetail: (isAddCollegeDetail) =>
    set(() => ({ isAddCollegeDetail })),
  isAddEssay: false,
  setIsAddEssay: (isAddEssay) => set(() => ({ isAddEssay })),
  isAddCommonEssay: false,
  setIsAddCommonEssay: (isAddCommonEssay) => set(() => ({ isAddCommonEssay })),
  isEditCommonEssay: false,
  setIsEditCommonEssay: (isEditCommonEssay) =>
    set(() => ({ isEditCommonEssay })),
  isDeleteCommonEssay: false,
  setIsDeleteCommonEssay: (isDeleteCommonEssay) =>
    set(() => ({ isDeleteCommonEssay })),
  editCommonEssayId: false,
  setEditCommonEssayId: (editCommonEssayId) =>
    set(() => ({ editCommonEssayId })),
  deleteCommonEssayId: false,
  setDeleteCommonEssayId: (deleteCommonEssayId) =>
    set(() => ({ deleteCommonEssayId })),
  isAddSupplement: false,
  setIsAddSupplement: (isAddSupplement) => set(() => ({ isAddSupplement })),
  isUploadCSV: false,
  setIsUploadCSV: (isUploadCSV) => set(() => ({ isUploadCSV })),
  isSuccessSnackbar: false,
  setIsSuccessSnackbar: (isSuccessSnackbar) =>
    set(() => ({ isSuccessSnackbar })),
  isUpdate: false,
  setIsUpdate: (isUpdate) => set(() => ({ isUpdate })),
  isUpdateCsvConfirmation: false,
  setIsUpdateCsvConfirmation: (isUpdateCsvConfirmation) =>
    set(() => ({ isUpdateCsvConfirmation })),
  allUserProfileData: [],
  setAllUserProfileData: (allUserProfileData) =>
    set(() => ({ allUserProfileData })),
  isCreateStripeAccountId: null,
  setIsCreateStripeAccountId: (isCreateStripeAccountId) =>
    set(() => ({ isCreateStripeAccountId })),
  isAskUniAddId: null,
  setIsAskUniAddId: (isAskUniAddId) => set(() => ({ isAskUniAddId })),
  updateYearDialog: false,
  setUpdateYearDialog: (updateYearDialog) => set(() => ({ updateYearDialog })),
  updateRoundDialog: false,
  setUpdateRoundDialog: (updateRoundDialog) =>
    set(() => ({ updateRoundDialog })),
}));

export default useAdminStore;
