import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { IconButton } from '@mui/material';
import { Person, Wallet } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
export const TeamDetailsSidebar = ({
  selectedItem = 0,
  setSelectedItem = () => {},
}) => {
  const menuItems = [
    {
      id: 0,
      label: 'Student',
      icon: <Person />,
    },
    {
      id: 2,
      label: 'Team Members',
      icon: <GroupIcon />,
    },
    {
      id: 1,
      label: 'Subscription',
      icon: <Wallet />,
    },
  ];

  return (
    <div className="w-full h-full flex flex-col border border-cream-200 rounded-2xl bg-white sm:bg-transparent">
      {menuItems.map((item) => (
        <div key={item.id} className="px-6 pt-6 cursor-pointer">
          <div
            className={`${
              selectedItem === item.id
                ? 'border bg-grey-900/8 rounded-lg font-medium'
                : ''
            } flex items-center justify-between hover:bg-grey-900/8 rounded-lg p-2`}
            onClick={() => setSelectedItem(item.id)}
          >
            <div className="flex items-center flex-1 justify-between">
              <div
                className={`${
                  selectedItem !== item.id
                    ? 'text-lead-500'
                    : 'text-grey-900 font-medium'
                } flex items-center gap-3`}
              >
                {item.icon}
                <div>{item.label}</div>
              </div>
              <IconButton aria-label="comment" sx={{ fontSize: '10px' }}>
                <ArrowForwardIosRoundedIcon
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: selectedItem !== item.id ? '#616675' : '#000000',
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
