import create from 'zustand';

const useInviteAFriendStore = create((set) => ({
  invitedMembers: [],
  invitedMembersId: {},
  setInvitedMembers: (invitedMembers) =>
    set(() => ({
      invitedMembers,
      invitedMembersId: invitedMembers.reduce(
        (acc, team) => ({ ...acc, [team.id]: team }),
        {}
      ),
    })),
  isFetchingInviteAFriendData: false,
  setIsFetchingInviteAFriendData: (isFetchingInviteAFriendData) =>
    set(() => ({ isFetchingInviteAFriendData })),
  isInvitingAFriend: false,
  setIsInvitingAFriend: (isInvitingAFriend) =>
    set(() => ({ isInvitingAFriend })),
  isReferingAFriend: false,
  setIsReferingAFriend: (isReferingAFriend) =>
    set(() => ({ isReferingAFriend })),
  isInviteBannerOpen: true,
  setIsInviteBannerOpen: (isInviteBannerOpen) =>
    set(() => ({ isInviteBannerOpen })),
}));

export default useInviteAFriendStore;
