import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import InputWithTooltip from './InputWithTooltip';
import ErrorIcon from '@mui/icons-material/Error';
import SelectOption from './SelectOption';
import { ReactComponent as StoryIcon } from '../../assets/svgs/story.svg';
import { ReactComponent as ApplicationIcon } from '../../assets/svgs/tooltipApplicationIcon.svg';
import { ReactComponent as FamilyIcon } from '../../assets/svgs/family.svg';
import { ReactComponent as TeacherIcon } from '../../assets/svgs/teacher.svg';
import { ReactComponent as SchoolIcon } from '../../assets/svgs/school.svg';
import { ReactComponent as PrivateIcon } from '../../assets/svgs/private.svg';
import Alert from './Alert';

export default function AddTeamMember({
  firstName = '',
  lastName = '',
  email = '',
  role = '',
  alertMessage = '',
  onChange = () => {},
  isFirstNameEmpty = false,
  isLastNameEmpty = false,
  isEmailEmpty = false,
  existingTeamMembers = [],
}) {
  const roleMenuData = [
    {
      id: 1,
      label: 'Family',
      icon: (
        <div className="w-5 h-5">
          <FamilyIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
    {
      id: 2,
      label: 'Teacher/Coach',
      icon: (
        <div className="w-5 h-5">
          <TeacherIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
    {
      id: 3,
      label: 'School Counselor',
      icon: (
        <div className="w-5 h-5">
          <SchoolIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
    {
      id: 4,
      label: 'Private Counselor',
      icon: (
        <div className="w-5 h-5">
          <PrivateIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
  ];
  const [formData, setFormData] = useState({
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    role: role || '',
  });
  const [isError, setIsError] = useState(false);
  const [teamMemberExists, setTeamMemberExists] = useState(false);
  // eslint-disable-next-line no-useless-escape
  const regexForEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const validateEmail = () => {
    if (formData.email.length > 0 && !regexForEmail.test(formData.email)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    if (existingTeamMembers.includes(formData.email)) {
      setTeamMemberExists(true);
    } else {
      setTeamMemberExists(false);
    }
  };

  useEffect(() => {
    onChange(formData);
    validateEmail();
    // eslint-disable-next-line
  }, [formData]);

  return (
    <div className="flex flex-col gap-4 sm:gap-8">
      <div className="text-[13px] text-lead-500">
        Add your parents, teachers, or counselors to your Team.
      </div>
      <div className="flex flex-col gap-4 sm:flex-row">
        <TextField
          error={isError || isEmailEmpty || teamMemberExists}
          required
          data-lpignore="true"
          label="Email"
          value={formData.email}
          fullWidth
          type="email"
          onBlur={validateEmail}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          helperText={
            isError ? (
              <div className="text-[#D32F2F] text-xs mt-2 flex items-center gap-1">
                <ErrorIcon fontSize="small" />
                Please enter a valid email address.
              </div>
            ) : isEmailEmpty ? (
              'Enter Email address'
            ) : teamMemberExists ? (
              'Team Member already exists'
            ) : (
              ''
            )
          }
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value?.toLowerCase() });
          }}
          sx={{
            '& .MuiFormHelperText-root': {
              marginLeft: '0px',
            },
          }}
        />
        <SelectOption
          options={roleMenuData}
          label="Role"
          value={
            roleMenuData.find((role) => role.label === formData.role)?.id || ''
          }
          onChange={(e) => {
            setFormData({
              ...formData,
              role: roleMenuData[e.target.value - 1]?.label,
            });
          }}
        />
      </div>
      <Alert className="w-full">{alertMessage}</Alert>
    </div>
  );
}
