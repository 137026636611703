import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import Alert from '../shared/Alert';
import SelectOption from '../shared/SelectOption';
import { ReactComponent as FamilyIcon } from '../../assets/svgs/family.svg';
import { ReactComponent as TeacherIcon } from '../../assets/svgs/teacher.svg';
import { ReactComponent as SchoolIcon } from '../../assets/svgs/school.svg';
import { ReactComponent as PrivateIcon } from '../../assets/svgs/private.svg';
import { ReactComponent as StudentIcon } from '../../assets/svgs/student.svg';
import useInviteAFriendStore from '../../stores/InviteAFriendStore';
import { GoogleEmailAlert } from '../shared/GoogleEmailAlert/GoogleEmailAlert';

const InviteAFriend = ({
  firstName = '',
  lastName = '',
  email = '',
  role = '',
  onChange = () => {},
  isFirstNameEmpty = false,
  isLastNameEmpty = false,
  isEmailEmpty = false,
  existingTeamMembers = [],
  isRoleEmpty = false,
  isParent = false,
}) => {
  const [formData, setFormData] = useState({
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    role: role || '',
  });

  const roleMenuData = [
    {
      id: 1,
      label: 'Student',
      icon: (
        <div className="w-5 h-5">
          <StudentIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
    {
      id: 2,
      label: 'Family',
      icon: (
        <div className="w-5 h-5">
          <FamilyIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
    {
      id: 3,
      label: 'Teacher/Coach',
      icon: (
        <div className="w-5 h-5">
          <TeacherIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
    {
      id: 4,
      label: 'School Counselor',
      icon: (
        <div className="w-5 h-5">
          <SchoolIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
    {
      id: 5,
      label: 'Private Counselor',
      icon: (
        <div className="w-5 h-5">
          <PrivateIcon
            style={{
              fill: '#F1F1F2',
              ':hover': {
                fill: 'white',
              },
            }}
          />
        </div>
      ),
    },
  ];

  const [isError, setIsError] = useState(false);
  const [teamMemberExists, setTeamMemberExists] = useState(false);
  const inviteStore = useInviteAFriendStore();
  // eslint-disable-next-line
  const regexForEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const validateEmail = () => {
    if (formData.email.length > 0 && !regexForEmail.test(formData.email)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    if (existingTeamMembers.includes(formData.email)) {
      setTeamMemberExists(true);
    } else {
      setTeamMemberExists(false);
    }
  };

  useEffect(() => {
    onChange(formData);
    validateEmail();
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    if (isParent && !inviteStore.isReferingAFriend) {
      setFormData({ ...formData, role: roleMenuData[0]?.label });
    }
    //eslint-disable-next-line
  }, [isParent, inviteStore.isReferingAFriend]);

  return (
    <div className="flex flex-col gap-4 sm:gap-8">
      <div className="text-[13px] text-lead-500">
        {inviteStore.isReferingAFriend
          ? `Refer your friends, teachers, and counselors to use Universily`
          : `Invite a student to be able to view the tasks they've added and collaborate with them.`}
      </div>
      <div className="flex-col sm:flex-row flex gap-4">
        <TextField
          required
          error={isFirstNameEmpty}
          label="First Name"
          value={formData.firstName}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          onChange={(e) => {
            setFormData({ ...formData, firstName: e.target.value });
          }}
          fullWidth
          sx={{
            '.MuiTooltip-tooltip': {
              maxWidth: '371px',
              borderRadius: '8px',
            },
          }}
          helperText={isFirstNameEmpty && `Enter First Name`}
        />
        <TextField
          required
          label="Last Name"
          value={formData.lastName}
          fullWidth
          error={isLastNameEmpty}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          onChange={(e) => {
            setFormData({ ...formData, lastName: e.target.value });
          }}
          helperText={isLastNameEmpty && `Enter Last Name`}
        />
      </div>
      <div className="flex flex-col sm:flex-row gap-4">
        <TextField
          error={isError || isEmailEmpty || teamMemberExists}
          required
          data-lpignore="true"
          label="Email"
          value={formData.email}
          fullWidth
          type="email"
          onBlur={validateEmail}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          helperText={
            isError ? (
              <div className="text-[#D32F2F] text-xs mt-2 flex items-center gap-1">
                <ErrorIcon fontSize="small" />
                Please enter a valid email address.
              </div>
            ) : isEmailEmpty ? (
              'Enter Email address'
            ) : teamMemberExists ? (
              'Team Member already exists'
            ) : (
              ''
            )
          }
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value?.toLowerCase() });
          }}
          sx={{
            '& .MuiFormHelperText-root': {
              marginLeft: '0px',
            },
          }}
        />
        <SelectOption
          options={roleMenuData}
          label="Role"
          error={isRoleEmpty}
          helperText={isRoleEmpty ? 'Select role' : ''}
          value={
            !inviteStore.isReferingAFriend
              ? roleMenuData[0].id
              : roleMenuData.find((role) => role.label === formData.role)?.id
          }
          onChange={(e) => {
            setFormData({
              ...formData,
              role: roleMenuData[e.target.value - 1]?.label,
            });
          }}
          disabled={!inviteStore.isReferingAFriend}
        />
      </div>
      {inviteStore.isReferingAFriend ? (
        <GoogleEmailAlert />
      ) : (
        <Alert className="w-full">
          <div className="text-[20px] text-lead-900">
            Your Team Member accounts are
            <span className="font-bold">completely free</span>
            and your Team Members will never be charged. For each of your
            friends that registers with Universily, you’ll earn{' '}
            <span className="font-bold">
              one free month of Universily Premium Membership!
            </span>
          </div>
        </Alert>
      )}
    </div>
  );
};
export default InviteAFriend;
