import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Chip } from '@mui/material';
import { unlimitedFamily } from '../../../constants/staticData';
import useTeamStore from '../../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';

const UnlimitedPlanCard = ({
    buySubscription = () => {},
    manageSubscription = () => {},
    currentLicenseType,
}) => {

    const licenseType = useTeamStore(useShallow((state) => currentLicenseType || state.selectedTeam?.licenseData?.licenseType));

    const [planButtonText, setPlanButtonText] = useState('Select');

    const [isCurrentPlan, setIsCurrentPlan] = useState(false);

    useEffect(() => {
        if(licenseType) {
            setPlanButtonText("Upgrade")
            setIsCurrentPlan(unlimitedFamily.plan === licenseType)
        } else {
            setPlanButtonText("Select")
            setIsCurrentPlan(unlimitedFamily.plan === 'freemium')
        }
    }, [licenseType])
    return (
        <div className={`border-2 rounded-lg flex justify-between gap-6 px-4 py-8 font-archivo w-full bg-white shadow-1 sm:h-full sm:overflow-auto ${isCurrentPlan ? "border-[#30b28b]" : 'border-gray-400'}`}>
            <div className='flex flex-col gap-3 flex-1'>
                <div aria-label='title' className='text-xl font-bold'>{unlimitedFamily?.title}</div>
                <div className='flex items-center justify-start gap-2 w-2/3'>
                    <div className='text-[56px] font-bold'>{unlimitedFamily?.price}</div>
                    <div className='text-lg'> {unlimitedFamily?.priceSubText}</div>
                </div>
                <div className='text-[#838489] text-lg font-medium w-full'>{unlimitedFamily?.planText}</div>
                <div className='flex flex-col w-full gap-1'>
                    {unlimitedFamily?.planFeatures?.map((feature, index) => (
                        <div key={index} className='flex items-center gap-1'>
                            <CheckCircleIcon fontSize="small" sx={{ color: '#30b28b' }}/>
                            <div className={`text-sm  ${!index ? 'text-black font-bold' : 'text-[#838489]' }`}>{feature}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex flex-col items-center gap-6 justify-center'>
                {isCurrentPlan && <Chip label="Current Plan" sx={{ backgroundColor: '#30b28b', color: "white" }}/>}
                {!isCurrentPlan && <Button
                    variant="contained"
                    className="rounded-lg !py-4 !px-6 h-max self-center !text-base !font-bold"
                    onClick={() => buySubscription(unlimitedFamily.duration, unlimitedFamily.mode)}
                >
                    {planButtonText}
                </Button>}
                {isCurrentPlan &&  <Button
                    variant="outlined"
                    className="rounded-lg !py-4 !px-6 !text-base h-max self-center !font-bold"
                    onClick={manageSubscription}    
                >
                    Modify Plan
                </Button>
                }
            </div>
        </div>
    );
}

export default UnlimitedPlanCard;