import create from 'zustand';

const useHonorStore = create((set) => ({
  honors: [],
  honorsById: {},
  setHonors: (honors) =>
    set(() => ({
      honors,
      honorsById: honors.reduce(
        (acc, honor) => ({
          ...acc,
          [honor.id]: honor,
        }),
        {}
      ),
    })),
  honorTypes: [],
  setHonorTypes: (honorTypes) => set(() => ({ honorTypes })),
  honorCount: null,
  setHonorCount: (honorCount) => set(() => ({ honorCount })),
  isFetchingHonors: false,
  isCopyingHonors: false,
  setIsCopyingHonors: (isCopyingHonors) => set(() => ({ isCopyingHonors })),
  isDeletingSnackbar: false,
  setIsDeletingSnackbar: (isDeletingSnackbar) =>
    set(() => ({ isDeletingSnackbar })),
  copyHonorsId: null,
  setCopyHonorsId: (copyHonorsId) => set(() => ({ copyHonorsId })),
  setIsFetchingHonors: (isFetchingHonors) => set(() => ({ isFetchingHonors })),
  addingHonors: false,
  setAddingHonors: (addingHonors) => set(() => ({ addingHonors })),
  editingHonors: false,
  setEditingHonors: (editingHonors) => set(() => ({ editingHonors })),
  isFunFactSnackbarOpen: false,
  setIsFunFactSnackbarOpen: (isFunFactSnackbarOpen) =>
    set(() => ({ isFunFactSnackbarOpen })),
  editingHonorsHeader: false,
  setEditingHonorsHeader: (editingHonorsHeader) =>
    set(() => ({ editingHonorsHeader })),
  isDeletingHonors: false,
  setIsDeletingHonors: (isDeletingHonors) => set(() => ({ isDeletingHonors })),
  deleteHonorId: null,
  setDeleteHonorId: (deleteHonorId) => set(() => ({ deleteHonorId })),
  addEditHonorId: null,
  setAddEditHonorId: (addEditHonorId) => set(() => ({ addEditHonorId })),
  addingOrEditingHonorDocs: false,
  setAddingOrEditingHonorDocs: (addingOrEditingHonorDocs) =>
    set(() => ({ addingOrEditingHonorDocs })),
  isAllHonorsPageOpen: false,
  setIsAllHonorsPageOpen: (isAllHonorsPageOpen) =>
    set(() => ({ isAllHonorsPageOpen })),
}));

export default useHonorStore;
