import React from 'react';
import Alert from '../Alert';

export const GoogleEmailAlert = () => {
  return (
    <Alert className="w-full">
      <div className="text-[16px] text-lead-900">
        Only use emails linked to
        <span className="font-bold"> Google Accounts</span> since Universily
        leverages Google Workspace functionality.
        <br />
      </div>
    </Alert>
  );
};
