// Firebase Collection constants

// MY STORY
export const ACTIVITIES = 'activity';
export const ACTIVITY_TYPES = 'activitytypes';
export const HONORS = 'honor';
export const HONOR_TYPES = 'honortypes';
export const NICHE = 'niche';
export const TESTING = 'tests';
export const SITTING = 'sitting';
export const PROFILE = 'profile';
export const TEAMS = 'teams';
export const GUIDANCE = 'guidances';
export const REVIEWER = 'reviewer';
export const COMMENT = 'comment';
export const COURSE = 'course';
export const DEMOGRAPHICS = 'demographics';
export const ESSAY = 'essay';
export const PARENT = 'parent';
export const ADDITIONAL_INFO = 'additional';
export const EDUCATION = 'education';
export const TEST_TYPES = 'testTypes';
export const TEST_SUBJECTS = 'subjects';
export const TEST_TARGETS = 'testTargets';
export const TEST_SCORES = 'testScores';
export const GPA_SCORES = 'gpaScores';
export const TEST_TYPE_SAT = 'SAT';
export const TEST_TYPE_ACT = 'ACT';
export const CHAT_ROOMS = 'chatRooms';
export const CHAT_MESSAGES = 'chatMessages';
export const ESSAY_TYPE_CORE = 'coreEssay';
export const ESSAY_TYPE_APPLICATION = 'applicationEssay';
export const REVIEWER_TEAM = 'reviewerTeam';

// MY APPLICATIONS
export const COLLEGES_LIST = 'collegesList';
export const SELECTED_COLLEGES = 'selectedColleges';
export const APPLICATION_TEMPLATES = 'applicationTemplates';
export const APPLICATIONS = 'applications';
export const COMMON_ESSAYS = 'commonEssays';

// SUBSCRIPTION
export const SUBSCRIPTION = 'subscription';

// Log in information
export const PROFILE_LOGIN = 'profileLogin';

// Referral Information
export const REFERRAL = 'referral';

export const UNIVERSILY_TEMPLATES = 'universilyTemplates';
