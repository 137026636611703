import { useTheme } from '@mui/system';
import { alpha } from '@mui/system';

const TopicChip = ({ color, icon }) => {
  const theme = useTheme();
  const chipColor = color ? theme.palette[color].main : '#000000';

  return (
    <div
      style={{
        borderRadius: '50%',
        color: chipColor,
        backgroundColor: alpha(chipColor, 0.1),
      }}
      className="flex items-center justify-center p-2.5"
    >
      {icon}
    </div>
  );
};

export default TopicChip;
