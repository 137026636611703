import { useEffect } from 'react';
import { useState } from 'react';
import Dialog from '../shared/Dialog';
import AddCoreEssay from './AddCoreEssay';

const AddCoreEssayDialog = ({
  onContinue = () => {},
  onCancel = () => {},
  collaborators = [],
  editEssayData = {},
  selectedCollaborators = [],
  isEditingEssay = false,
  isAdding = false,
  ...props
}) => {
  const [essayData, setEssayData] = useState({});
  const [error, setError] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editEssayData?.essayCategory || editEssayData?.reviewers) {
      setEssayData({
        essayCategory: editEssayData.essayCategory,
        sharedWith: collaborators.filter((teamMembers) =>
          editEssayData?.reviewers?.includes(teamMembers?.id)
        ),
      });
    } else {
      setEssayData({ sharedWith: collaborators });
    }
    //eslint-disable-next-line
  }, [editEssayData]);

  return (
    <Dialog
      {...props}
      onContinue={async () => {
        setIsLoading(true);
        await onContinue(essayData);
        setIsLoading(false);
        // setEssayData({});
      }}
      onCancel={() => {
        setEssayData({});
        setError({});
        onCancel();
      }}
      continueText={isEditingEssay ? 'Update' : 'Add'}
      isLoading={isLoading}
    >
      <AddCoreEssay
        essayData={essayData}
        setEssayData={setEssayData}
        error={error}
        isAdding={isAdding}
        setError={setError}
        collaborators={collaborators}
        // selectedCollaborators={selectedCollaborators}
        isEditingEssay={isEditingEssay}
      />
    </Dialog>
  );
};

export default AddCoreEssayDialog;
