import React from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import Tooltip from './Tooltip';

const InputWithTooltip = ({ title, ...props }) => {
  return (
    <Tooltip
      placement="right-start"
      title={title}
      sx={props.sx ? props.sx : {}}
    >
      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
        fullWidth
        {...props}
      />
    </Tooltip>
  );
};

export default InputWithTooltip;
