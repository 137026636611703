import { useEffect } from 'react';
import { firebaseDataType } from '../constants/other';
import { COMMON_ESSAYS, PROFILE, PROFILE_LOGIN } from '../firebase/constants';
import { firebaseQueryOperators } from '../firebase/queryBuilder';
import { useStoryItemListener } from '../hooks';
import useAdminStore from '../stores/AdminStore';

const AdminContainer = () => {
  const { setCommonEssaysData, setLoginPageData, setAllUserProfileData } =
    useAdminStore();

  const { data: essayList } = useStoryItemListener(
    COMMON_ESSAYS,
    firebaseDataType.METADATA,
    [
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ]
  );

  useEffect(() => {
    setCommonEssaysData(essayList);
    //eslint-disable-next-line
  }, [essayList]);

  const { data: userData } = useStoryItemListener(
    PROFILE_LOGIN,
    firebaseDataType.METADATA
  );
  const { data: allUserData } = useStoryItemListener(
    PROFILE,
    firebaseDataType.METADATA
  );

  useEffect(() => {
    if (allUserData) {
      setAllUserProfileData(allUserData);
    }
    //eslint-disable-next-line
  }, [allUserData]);

  useEffect(() => {
    const sortedData = userData?.sort((a, b) => {
      return (
        b?.lastLoggedInAt?.toDate().valueOf() -
        a?.lastLoggedInAt?.toDate().valueOf()
      );
    });
    setLoginPageData(sortedData);
    //eslint-disable-next-line
  }, [userData]);

  return <></>;
};

export default AdminContainer;
