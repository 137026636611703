import React from 'react';
import { TextField } from '@mui/material';
import Dialog from '../shared/Dialog';

const EditTeamNameDialog = ({ teamStore, onEditTeamName }) => {
  const handleContinue = async () => {
    await onEditTeamName();
  };

  const handleCancel = () => {
    teamStore.setIsEditingTeamName(false);
  };

  const handleChange = (e) => {
    teamStore.setEditTeamNameData({
      ...teamStore.editTeamNameData,
      teamName: e.target.value,
    });
  };

  return (
    <Dialog
      title="Edit Team Name"
      onContinue={handleContinue}
      onCancel={handleCancel}
      open={teamStore.isEditingTeamName}
      onClose={handleCancel}
    >
      <div className="flex flex-col gap-4 sm:gap-8">
        <TextField
          label="Team Name"
          value={teamStore.editTeamNameData?.teamName}
          onChange={handleChange}
          type="text"
          placeholder="Enter new team name"
        />
      </div>
    </Dialog>
  );
};

export default EditTeamNameDialog;
