import create from 'zustand';

const useActivityStore = create((set) => ({
  activities: [],
  activitiesById: {},
  setActivities: (activities) =>
    set(() => ({
      activities,
      activitiesById: activities.reduce(
        (acc, activity) => ({ ...acc, [activity.id]: activity }),
        {}
      ),
    })),
  activityTypes: [],
  setActivityTypes: (activityTypes) => set(() => ({ activityTypes })),
  activityCount: null,
  setActivityCount: (activityCount) => set(() => ({ activityCount })),
  isFetchingActivities: false,
  setIsFetchingActivities: (isFetchingActivities) =>
    set(() => ({ isFetchingActivities })),
  addingActivity: false,
  setAddingActivity: (addingActivity) => set(() => ({ addingActivity })),
  isCopyingActivity: false,
  setIsCopyingActivity: (isCopyingActivity) =>
    set(() => ({ isCopyingActivity })),
  copyActivityId: null,
  setCopyActivityId: (copyActivityId) => set(() => ({ copyActivityId })),
  isDeletingActivity: false,
  setIsDeletingActivity: (isDeletingActivity) =>
    set(() => ({ isDeletingActivity })),
  isDeletingSnackbar: false,
  setIsDeletingSnackbar: (isDeletingSnackbar) =>
    set(() => ({ isDeletingSnackbar })),
  editingActivity: false,
  setEditingActivity: (editingActivity) => set(() => ({ editingActivity })),
  editingActivityHeader: false,
  setEditingActivityHeader: (editingActivityHeader) =>
    set(() => ({ editingActivityHeader })),
  isFunFactSnackbarOpen: false,
  setIsFunFactSnackbarOpen: (isFunFactSnackbarOpen) =>
    set(() => ({ isFunFactSnackbarOpen })),
  deleteActivityId: null,
  setDeleteActivityId: (deleteActivityId) => set(() => ({ deleteActivityId })),
  addEditActivityId: null,
  setAddEditActivityId: (addEditActivityId) =>
    set(() => ({ addEditActivityId })),
  addingOrEditingDocs: false,
  setAddingOrEditingDocs: (addingOrEditingDocs) =>
    set(() => ({ addingOrEditingDocs })),
  isAllActivitiesPageOpen: false,
  setIsAllActivitiesPageOpen: (isAllActivitiesPageOpen) =>
    set(() => ({ isAllActivitiesPageOpen })),
}));

export default useActivityStore;
