import { Avatar } from '@mui/material';
import { AutoComplete } from '../shared/AutoComplete';

export const CollegeAutoComplete = ({ options = [], ...props }) => {
  return (
    <AutoComplete
      label="College Name"
      options={options}
      filterOptions={(options, { inputValue }) =>
        options.filter(
          (item) =>
            item?.collegeData?.collegeName
              ?.toLowerCase()
              ?.includes(inputValue.toLowerCase()) ||
            item?.collegeData?.collegeHiddenName
              ?.toLowerCase()
              ?.includes(inputValue.toLowerCase()) ||
            item?.collegeData?.collegeShortName
              ?.toLowerCase()
              ?.includes(inputValue.toLowerCase())
        )
      }
      getOptionLabel={(option) => option?.collegeData?.collegeName}
      disableClearable={true}
      renderOption={(props, option) => (
        <li
          {...props}
          className="flex gap-2 px-4 py-2 cursor-pointer rounded hover:bg-brand-blue-50"
          key={option.id}
        >
          <Avatar
            src={option?.collegeData?.collegeLogoURL}
            sx={{
              height: '20px',
              width: '20px',
            }}
          />
          <div className="text-lead-500 text-sm">
            {option?.collegeData?.collegeName}
          </div>
        </li>
      )}
      {...props}
    />
  );
};
