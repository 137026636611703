import { Chip as CHIP } from '@mui/material';
import { styled } from '@mui/system';
import { alpha } from '@mui/system';

const Chip = styled(CHIP)(({ theme, ...props }) => {
  const chipColor =
    props?.color && props?.color !== ''
      ? theme.palette[props?.color].main
      : '#000000';
  return {
    borderRadius: 4,
    padding: '6px 12px',
    color: chipColor,
    backgroundColor: alpha(chipColor, 0.1),
  };
});

export default Chip;
