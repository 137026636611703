import { Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '../shared/Button';
import SelectOption from '../shared/SelectOption';
import { ReactComponent as SeniorIcon } from '../../assets/svgs/senior.svg';
import { ReactComponent as JuniorIcon } from '../../assets/svgs/junior.svg';
import { ReactComponent as SophomoreIcon } from '../../assets/svgs/sophomore.svg';
import { ReactComponent as FreshmenIcon } from '../../assets/svgs/freshmen.svg';
import { InputAdornment } from '@mui/material';
import { useState } from 'react';
import Dialog from '../shared/Dialog';
import { InputField } from '../shared/InputField';
import useThemeStore from '../../stores/ThemeStore';
import { Checkbox } from '../shared/Checkbox';

const MyAccountDetailsForm = ({
  firstName = '',
  lastName = '',
  phoneNumber = '',
  email = '',
  schoolName = '',
  address = {
    line2: '',
    state: '',
    city: '',
    postal_code: '',
    country: '',
    line1: '',
  },
  grade = '',
  highschoolLink = '',
  receiveNotifications = false,
  open,
  onClose = () => {},
  onSave = () => {},
  onChange = () => {},
  loggedInType = '',
  onChangePassword = () => {},
  linkGoogleAccount = () => {},
  isParent,
}) => {
  const phoneNumberRegex =
    /^(?:\(\d{3}\)\s*|\d{3}(?:-|\.)?)\d{3}(?:-|\.)?\d{4}$/;
  const [error, setError] = useState({});
  const { isMobile } = useThemeStore();
  const [userData, setUserData] = useState({
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    schoolName: schoolName || '',
    address: address || '',
    grade: grade || '',
    highschoolLink: highschoolLink || '',
    receiveNotifications: receiveNotifications || false,
  });

  const gradeMenuData = [
    {
      id: 1,
      grade: '9',
      label: 'Freshman | Grade 9',
      icon: <FreshmenIcon />,
    },
    {
      id: 2,
      grade: '10',
      label: 'Sophomore | Grade 10',
      icon: <SophomoreIcon />,
    },
    {
      id: 3,
      grade: '11',
      label: 'Junior | Grade 11',
      icon: <JuniorIcon />,
    },
    {
      id: 4,
      grade: '12',
      label: 'Senior | Grade 12',
      icon: <SeniorIcon />,
    },
  ];
  return (
    <Dialog
      open={open}
      onCancel={onClose}
      continueText="Update"
      onContinue={() => {
        if (
          !phoneNumberRegex.test(userData.phoneNumber) &&
          userData.phoneNumber !== ''
        ) {
          setError({
            field: 'phoneNumber',
            message: 'Please enter valid phone number',
          });
          return;
        }
        onSave(userData);
        onClose();
      }}
      title="Edit profile"
      isHeight={isMobile && true}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          <div className="gap-4 flex flex-col sm:grid sm:grid-cols-3">
            <TextField
              value={userData?.firstName}
              required
              id="outlined-name"
              label="First Name"
              className="col-span-1"
              onChange={(event) =>
                setUserData({ ...userData, firstName: event.target.value })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 4px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <TextField
              value={userData?.lastName}
              required
              id="outlined-name"
              label="Last Name"
              className="col-span-1"
              onChange={(event) =>
                setUserData({ ...userData, lastName: event.target.value })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 4px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <TextField
              value={userData?.phoneNumber}
              id="outlined-name"
              label="Phone Number (Optional)"
              className="col-span-1"
              error={error.field === 'phoneNumber'}
              helperText={error.field === 'phoneNumber' && error.message}
              onChange={(event) =>
                setUserData({ ...userData, phoneNumber: event.target.value })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 8px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex flex-col sm:grid sm:grid-cols-4 gap-4">
            <TextField
              value={userData?.schoolName}
              id="outlined-name"
              label="High school name, City/State (Optional)"
              placeholder="Tell us where you attend high school"
              className="col-span-2"
              onChange={(event) =>
                setUserData({ ...userData, schoolName: event.target.value })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 12px',
                  },
                },
              }}
              fullWidth={isParent ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />

            <SelectOption
              value={
                gradeMenuData.find((grade) => grade.grade === userData?.grade)
                  ?.id
              }
              options={gradeMenuData}
              label="Grade"
              className="col-span-2"
              onChange={(event) =>
                setUserData({
                  ...userData,
                  grade: gradeMenuData[event.target.value - 1]?.grade,
                })
              }
            />
          </div>
          <InputField
            id="outlined-name"
            value={userData?.highschoolLink}
            placeholder="Usually Naviance or SCOIR"
            label="Link to High School Counselor system"
            onChange={(event) =>
              setUserData({ ...userData, highschoolLink: event.target.value })
            }
          />
          <TextField
            id="outlined-name"
            value={userData?.address?.line1}
            label="Address line 1"
            onChange={(event) =>
              setUserData({
                ...userData,
                address: {
                  ...userData?.address,
                  line1: event.target.value,
                },
              })
            }
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                span: {
                  margin: '0px 8px',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-name"
            value={userData?.address?.line2}
            label="Address line 2"
            onChange={(event) =>
              setUserData({
                ...userData,
                address: {
                  ...userData?.address,
                  line2: event.target.value,
                },
              })
            }
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                span: {
                  margin: '0px 8px',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
          <div className="gap-4 flex flex-col sm:grid sm:grid-cols-3">
            <TextField
              value={userData?.address?.city}
              id="outlined-name"
              label="City"
              className="col-span-1"
              onChange={(event) =>
                setUserData({
                  ...userData,
                  address: {
                    ...userData?.address,
                    city: event.target.value,
                  },
                })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 4px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <TextField
              value={userData?.address?.state}
              id="outlined-name"
              label="State"
              className="col-span-1"
              onChange={(event) =>
                setUserData({
                  ...userData,
                  address: {
                    ...userData.address,
                    state: event.target.value,
                  },
                })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 4px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <TextField
              value={userData?.address?.postal_code}
              id="outlined-name"
              label="Postal Code"
              className="col-span-1"
              error={error.field === 'phoneNumber'}
              helperText={error.field === 'phoneNumber' && error.message}
              onChange={(event) =>
                setUserData({
                  ...userData,
                  address: {
                    ...userData?.address,
                    postal_code: event.target.value,
                  },
                })
              }
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  span: {
                    margin: '0px 8px',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </div>
          <TextField
            id="outlined-name"
            value={userData?.address?.country}
            className="self-start"
            label="Country"
            onChange={(event) =>
              setUserData({
                ...userData,
                address: {
                  ...userData.address,
                  country: event.target.value,
                },
              })
            }
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                span: {
                  margin: '0px 8px',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </div>
        {/* <Divider /> */}
      </div>
    </Dialog>
  );
};

export default MyAccountDetailsForm;
