import { useEffect, useState } from 'react';
import Dialog from '../shared/Dialog';
import SelectTeamMembers from '../shared/SelectTeamMembers';

export const ShareUtility = ({
  title = 'Share with Team Members',
  open = false,
  onContinue = async () => {},
  onCancel = () => {},
  reviewers = [],
  teamMemberData = [],
}) => {
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setSelectedTeamMembers(reviewers);
  }, [reviewers]);

  useEffect(() => {
    if (selectedTeamMembers?.length > 0) {
      if (reviewers?.length === selectedTeamMembers?.length) {
        const checkData = prepareBooleans();
        if (checkData.some((item) => item === false)) {
          setIsError(true);
        } else {
          setIsError(false);
        }
      } else {
        setIsError(false);
      }
    } else {
      setIsError(true);
    }
    //eslint-disable-next-line
  }, [selectedTeamMembers]);

  const prepareBooleans = () => {
    const booleans = reviewers.map((reviewer) => {
      return selectedTeamMembers.includes(reviewer);
    });
    return booleans;
  };

  return (
    <Dialog
      title={title}
      continueText="Send"
      isLoading={isLoading}
      open={open}
      onContinue={async () => {
        setIsLoading(true);
        await onContinue(selectedTeamMembers);
        setIsLoading(false);
      }}
      onCancel={() => {
        onCancel();
      }}
      isError={isError}
    >
      <div className="flex flex-col gap-6">
        <div className="text-xs text-primary-black/50">
          You can collaborate by sharing with existing Team Members or add a new
          one.
        </div>

        <SelectTeamMembers
          label="Select Team Members"
          options={teamMemberData}
          value={selectedTeamMembers}
          menuListTitle="Select your Existing Team Members"
          onChange={(e, teamMembers) => {
            setSelectedTeamMembers(teamMembers);
          }}
        />
      </div>
    </Dialog>
  );
};
