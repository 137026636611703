import { IconButton, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { ReactComponent as SendIcon } from '../../assets/svgs/sendIcon.svg';
import useChatStore from '../../stores/ChatStore';
import { ASK_UNI_MAIL, PROMPT_TYPES } from '../../constants/staticData';
import ChatBubble from './ChatBubble';
import { AI_TEMPLATES } from '../../constants/keywords';

const ChatInputField = ({
  onSend = () => {},
  onSendMessage = () => {},
  receipientDetails = {},
}) => {
  const [textMessage, setTextMessage] = useState('');

  const { isAiTyping, promptStoryType } = useChatStore();

  const isAI = [ASK_UNI_MAIL].includes(receipientDetails?.email);

  const PROMPTS_LIST = useMemo(() => {
    if (promptStoryType) {
      const selectedList = PROMPT_TYPES[promptStoryType];
      return selectedList || [];
    } else return [];
  }, [promptStoryType]);

  return (
    <div className="items-center gap-4">
      {isAI && PROMPTS_LIST?.length > 0 ? (
        <div className="border-t mx-4 border-cream-500">
          <div className="text-2xs py-2 px-6 flex justify-end text-grey-300">
            Here are some questions you can ask:
          </div>
          <div className="w-full flex justify-end flex-col gap-2 text-grey-300 font-sm py-1 px-6">
            {PROMPTS_LIST?.map(({ id, label, value }) => (
              <ChatBubble
                text={label}
                key={id}
                direction={'right'}
                isPrompt={PROMPTS_LIST?.length > 0}
                onClickPrompt={() => {
                  if (!isAiTyping) {
                    onSendMessage(
                      label,
                      () => {
                        setTextMessage('');
                      },
                      value,
                      id
                    );
                  }
                }}
              />
            ))}
          </div>
        </div>
      ) : null}
      <div
        className={`pt-6 px-6 flex items-center border-t border-cream-400/20 gap-4 ${
          !isAI ? 'py-6' : ''
        }`}
      >
        <TextField
          id="chat-input-field"
          multiline={true}
          placeholder="Type something"
          className="text-grey-900 w-full text-xs"
          value={textMessage}
          rows={4}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
              padding: '8px',
              fontSize: '12px',
            },
            '& .MuiInputBase-root': {
              fontSize: '12px',
            },
          }}
          onKeyDown={async (event) => {
            if (event.key === 'Enter' && textMessage !== '' && !isAiTyping) {
              onSendMessage(
                textMessage,
                () => {
                  setTextMessage('');
                  document.getElementById('chat-input-field')?.blur();
                  setTimeout(() => {
                    document.getElementById('chat-input-field')?.focus();
                  }, 0);
                },
                '',
                AI_TEMPLATES.INTRO_PROMPT
              );
            }
          }}
          onChange={(event) => setTextMessage(event.target.value)}
        />
        <IconButton
          onClick={async () => {
            if (textMessage !== '' && !isAiTyping) {
              await onSendMessage(
                textMessage,
                () => {
                  setTextMessage('');
                },
                '',
                AI_TEMPLATES.INTRO_PROMPT
              );
            }
          }}
          disabled={isAiTyping}
        >
          <SendIcon />
        </IconButton>
      </div>
      {isAI ? (
        <div className="flex items-center px-2 py-2 text-2xs justify-center text-center text-grey-200">
          AI-generated content is not always reliable. Verify before using.
        </div>
      ) : null}
    </div>
  );
};

export default ChatInputField;
