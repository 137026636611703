import { SITE_TITLE } from '../../constants/other';
import PageSiteTitle from '../pageSiteTitle';
import MyStudentDayZero from './MyStudentDayZero';
import MyStudentListPanel from './MyStudentListPanel';

const MyStudentPage = ({
  teamMembers = [],
  onAddTeamMember = () => {},
  sendInviteToReviewer = () => {},
}) => {
  return (
    <div className="h-full w-full bg-cream-50">
      <PageSiteTitle title={SITE_TITLE + ' - My Students'} />
      {teamMembers?.length > 0 ? (
        <div className="p-4 pt-6 h-full">
          <MyStudentListPanel
            teamMembers={teamMembers}
            onAddTeamMember={onAddTeamMember}
            sendInviteToReviewer={sendInviteToReviewer}
          />
        </div>
      ) : (
        <MyStudentDayZero onAddTeamMember={onAddTeamMember} />
      )}
    </div>
  );
};

export default MyStudentPage;
