import { useParams, useNavigate } from 'react-router-dom';
import useTeamStore from '../stores/TeamStore';

const TeamGuard = ({ children }) => {
  const { teamID } = useParams();
  const navigate = useNavigate();
  const TEAMS = useTeamStore((state) => state.teams);
  if (!teamID) {
    // Redirect to myHome if teamID is missing
    navigate('/myHome');
    return null;
  }

  return children;
};

export default TeamGuard;
