import { ReactComponent as TrophyIcon } from '../../assets/svgs/trophyChat.svg';
import { ReactComponent as PedalBikeIcon } from '../../assets/svgs/bicycleChat.svg';
import { ReactComponent as BusinessCenterIcon } from '../../assets/svgs/briefcaseChat.svg';
import { ReactComponent as BookIcon } from '../../assets/svgs/bookIcon.svg';
import { ReactComponent as ApplicationIcon } from '../../assets/svgs/columns-gap.svg';
import { ReactComponent as EssayIcon } from '../../assets/svgs/file-earmark-text.svg';
import { ReactComponent as StarIcon } from '../../assets/svgs/Niche.svg';
import { ReactComponent as MyStoryIcon } from '../../assets/svgs/view-list.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TopicChip from '../shared/TopicChip';
import { Avatar, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import Menu from '../shared/Menu';
import MenuItem from '../shared/MenuItem';
import Tooltip from '../shared/Tooltip';

const ChatListItem = ({
  userId = '',
  id = '',
  type = '',
  utilityType,
  avatar,
  name,
  reviewerId = '',
  onChatClick = () => {},
  createDMChannel = () => {},
  onArchiveTopicMessage = () => {},
  archivedBy = [],
  markMessagesAsRead = () => {},
  markMessagesAsUnread = () => {},
  unreadCount = 0,
  channelExists = false,
  teamMemberExists = false,
}) => {
  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    archivedBy?.includes(userId) ? setIsArchived(true) : setIsArchived(false);
    // eslint-disable-next-line
  }, [archivedBy]);

  const [anchorEl, setAnchorEl] = useState(false);

  const getUtilityChip = () => {
    switch (utilityType?.toLowerCase()) {
      case 'activities':
        return <TopicChip color="purple" icon={<PedalBikeIcon />} />;
      case 'honors':
        return <TopicChip color="yellow" icon={<TrophyIcon />} />;
      case 'courses':
        return <TopicChip color="green" icon={<BusinessCenterIcon />} />;
      case 'niche':
        return <TopicChip color="darkBlue" icon={<StarIcon />} />;
      case 'my story':
        return <TopicChip color="darkGrey" icon={<MyStoryIcon />} />;
      case 'essays':
        return <TopicChip color="red" icon={<EssayIcon />} />;
      case 'colleges':
        return <TopicChip color="blue" icon={<ApplicationIcon />} />;
      case 'tests':
        return <TopicChip color="lightPurple" icon={<BookIcon />} />;
      default:
        return <Avatar />;
    }
  };

  const getDirectMessageChip = () => {
    return (
      <Avatar
        src={avatar}
        sx={{
          '& .MuiAvatar-root': {
            width: '20px',
            height: '20px',
            fontSize: 8,
            color: '#494E5B',
            backgroundColor: '#F1F1F2',
            borderColor: '#FFFFFF',
          },
        }}
      />
    );
  };

  return (
    <div
      className="cursor-pointer flex items-center gap-11 py-2"
      onClick={(e) => {
        e.stopPropagation();
        if (type === 'topic' || type === 'archivedTopic') {
          onChatClick(id, type, unreadCount);
        } else {
          createDMChannel(reviewerId, unreadCount);
        }
      }}
    >
      <div className="flex items-center gap-3 w-3/5">
        <div>
          {type === 'topic' || type === 'archivedTopic'
            ? getUtilityChip()
            : getDirectMessageChip()}
        </div>
        <div className="w-full">
          <Tooltip title={name}>
            <div
              id="chat-list-item"
              className={`overflow-hidden w-full text-grey-900 text-sm truncate ${
                unreadCount > 0 ? 'font-semibold' : ''
              }`}
            >
              {name}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="ml-auto flex items-center gap-5">
        {unreadCount > 0 && (
          <div className="h-[18px] w-[18px] text-white flex items-center justify-center bg-success-90 text-[10px] rounded-2xl">
            {unreadCount}
          </div>
        )}
        <Tooltip title="More Actions">
          <div>
            <IconButton
              size="small"
              aria-label="Actions"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                e.stopPropagation();
              }}
            >
              <MoreVertIcon fontSize="medium" className="text-cream-450" />
            </IconButton>
          </div>
        </Tooltip>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClick={(e) => {
            setAnchorEl(null);
            e.stopPropagation();
          }}
          onClose={(e) => {
            setAnchorEl(null);
            e.stopPropagation();
          }}
        >
          <MenuItem
            key="edit"
            title="Edit Topic"
            onClick={() => {
              if (channelExists) {
                if (unreadCount) {
                  markMessagesAsRead(id, unreadCount);
                } else {
                  markMessagesAsUnread(id);
                }
              }
            }}
          >
            {unreadCount ? 'Mark as Read' : 'Mark as Unread'}
          </MenuItem>
          <MenuItem
            title="Archive Topic"
            disabled={!teamMemberExists}
            onClick={() => {
              if (channelExists) {
                onArchiveTopicMessage(
                  id,
                  userId,
                  archivedBy,
                  type === 'topic' || type === 'archivedTopic'
                    ? 'Topic'
                    : 'Message'
                );
              }
            }}
            key="archive"
          >
            {`${isArchived || !teamMemberExists ? 'Unarchive' : 'Archive'} ${
              type === 'topic' || type === 'archivedTopic' ? 'Topic' : 'Message'
            }`}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ChatListItem;
