import { InputField } from './InputField';
import { GoogleEmailAlert } from './GoogleEmailAlert/GoogleEmailAlert';

export default function AddStudent({
  setStudentData = () => {},
  studentData = {},
}) {
  // States

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentData({
      ...studentData,
      [name]: value,
    });
  };

  return (
    <div className="flex flex-col gap-4 sm:gap-8">
      <div className="text-[13px] text-lead-500">
        Add a new team by inviting a Student.
      </div>
      <div className="w-full flex flex-col gap-4 sm:flex-row">
        <form className="w-full">
          <div className="w-full flex flex-col gap-4">
            <InputField
              label="Student Name"
              required
              type="text"
              id="name"
              name="name"
              variant="outlined"
              background="#FCFCF9"
              value={studentData.name}
              onChange={handleChange}
            />
            <InputField
              label="Student Email"
              required
              type="email"
              id="email"
              name="email"
              variant="outlined"
              background="#FCFCF9"
              value={studentData.email}
              onChange={handleChange}
            />
          </div>
        </form>
      </div>
      <GoogleEmailAlert />
    </div>
  );
}
