import { Add } from '@mui/icons-material';
import { essayCategory } from '../../constants/other';
import SelectTeamMembers from '../shared/SelectTeamMembers';
import Tooltip from '../shared/Tooltip';
import { useState } from 'react';

const AddCoreEssay = ({
  essayData = {},
  setEssayData = () => {},
  error = {},
  setError = () => {},
  setDisableAdd = () => {},
  collaborators = [],
  selectedCollaborators = [],
  isEditingEssay = false,
  isAdding = false,
}) => {


    // To set the default team members
    function defaultTeamMembers() {
      if (isAdding) {
        return collaborators;
      }
      return selectedCollaborators;
    }
  
    const [selectedTeamMembers, setSelectedTeamMembers] = useState(() =>
      defaultTeamMembers()
    );

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="font-bold text-layer-blur">
            {!isEditingEssay
              ? 'Select a Template (optional)'
              : 'Select a Type (optional)'}
          </div>
          <div className="text-xs text-lead-500">
            {!isEditingEssay
              ? 'You can write your Core Essay from scratch or use one of these templates to help you get started.'
              : 'You can assign an Essay Type to make it easier to search for later if you want to reuse this essay.'}
          </div>
        </div>
        <div>
          <div className="grid grid-cols-3 gap-4">
            {essayCategory.map((essayType) => (
              <div
                key={essayType.tag}
                className={`cursor-pointer border rounded-lg  text-grey-800 font-medium flex bg-white p-6 ${
                  essayData?.essayCategory === essayType.tag
                    ? 'bg-brand-blue-500/10'
                    : 'border-lead-900/23'
                }`}
                onClick={() =>
                  setEssayData({
                    ...essayData,
                    essayCategory: essayType.tag,
                    essayName: essayType.name,
                  })
                }
              >
                {essayType.name}
              </div>
            ))}
            {!isEditingEssay && (
              <Tooltip
                title={`We recommend you to use the Core Essays as a 
                            reference to copy or any of the templates to fast 
                          track your Essay writing. However you may start 
                            with a blank page as well.`}
              >
                <div
                  className={`cursor-pointer flex gap-4 border border-dashed rounded-lg text-[#383838] font-medium bg-white p-6 ${
                    !essayData?.essayCategory
                      ? 'bg-brand-blue-500/10'
                      : 'border-lead-900/23'
                  }`}
                  onClick={() =>
                    setEssayData({
                      ...essayData,
                      essayCategory: '',
                      essayName: 'Untitled',
                    })
                  }
                >
                  <Add
                    sx={{
                      color: '#383838',
                    }}
                  />
                  <div>Start from a Blank Page</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-xs text-lead-500">
          Select a team member with whom you wish to collaborate.
        </div>
        <SelectTeamMembers
          label="Select Team Member (optional)"
          multiple={true}
          options={collaborators}
          value={selectedTeamMembers}
          menuListTitle="Select your Existing Team Members"
          tooltipTitle={`You can select or add Team Members with whom you wish to collaborate`}
          onChange={(e, teamMembers) => {
            setSelectedTeamMembers(teamMembers);
          }}
        />
      </div>
    </div>
  );
};

export default AddCoreEssay;
