import Dialog from './Dialog';
import AddTeamMember from './AddTeamMember';
import { useState } from 'react';

const AddTeamMemberDialog = ({
  memberData = [],
  onContinue = () => {},
  isEditDialog = false,
  onCancel = () => {},
  existingTeamMembers = [],
  disabled = false,
  setDisabled = () => {},
  ...args
}) => {
  const [isFirstNameEmpty, setIsFirstNameEmpty] = useState(false);
  const [isLastNameEmpty, setIsLastNameEmpty] = useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [teamMemberData, setTeamMemberData] = useState({});
  const onChange = (teamMember) => {
    setTeamMemberData(teamMember);
  };

  const getAlertMessage = () => {
    if (!isEditDialog) {
      return (
        <div className="text-[16px] text-lead-900">
          Your team members can have{' '}
          <span className="font-bold">free accounts</span> but use emails linked
          to Google as Universily uses Google Workspace.
          <br />
        </div>
      );
    }
    return `To update email or role, please remove and re-add the team member which 
    will send them an invitation email.`;
  };
  return (
    <Dialog
      isHeight={false}
      disabled={disabled}
      continueText={!isEditDialog ? 'Add' : 'Update'}
      title={!isEditDialog ? 'Add Team Member' : 'Edit Team Member Details'}
      onContinue={() => {
        if (teamMemberData?.email === '') setIsEmailEmpty(true);
        else setIsEmailEmpty(false);
        if (teamMemberData?.email !== '') {
          if (disabled) return;

          setDisabled(true);
          onContinue(teamMemberData);
        }
      }}
      onCancel={() => {
        onCancel();
        setIsFirstNameEmpty(false);
        setIsLastNameEmpty(false);
        setIsEmailEmpty(false);
      }}
      {...args}
    >
      <AddTeamMember
        firstName={memberData.firstName}
        lastName={memberData.lastName}
        email={memberData.email}
        role={memberData.role}
        alertMessage={getAlertMessage()}
        onChange={onChange}
        isFirstNameEmpty={isFirstNameEmpty}
        isLastNameEmpty={isLastNameEmpty}
        isEmailEmpty={isEmailEmpty}
        existingTeamMembers={existingTeamMembers}
      />
    </Dialog>
  );
};

export default AddTeamMemberDialog;
