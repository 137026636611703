import {
  Avatar,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuItem from './MenuItem';
import Tooltip from './Tooltip';

const useStyles = makeStyles({
  select: {
    borderRadius: '8px !important',
    marginTop: 4,
    '& ul': {
      padding: 8,
    },
    '& li': {
      fontSize: 14,
      fontWeight: '500',
      '.Mui-focusVisible': {
        backgroundColor: 'red !important',
      },
    },
    '& .Mui-selected': {
      background: 'linear-gradient(0deg, #E9F0FF, #E9F0FF), #FFFFFF',
      color: '#1958CD',
    },

    '& .MuiMenuItem-root:focus-visible': {
      backgroundColor: 'white !important',
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#E9F0FF !important',
    },
  },
});

export default function SelectOption({ maxWidth = '100%', ...props }) {
  const classes = useStyles();

  return (
    <FormControl
      sx={{
        width: '100%',
        '& .MuiInputAdornment-root': {
          display: 'none',
        },
        '& .MuiSelect-select': {
          paddingLeft: '14px',
          div: {
            overflow: 'hidden',
            // paddingLeft: '14px',
          },
        },
        '& .MuiOutlinedInput-root': {
          padding: 0,
          '& .MuiInputAdornment-root': {
            display: 'none',
          },
        },
        '& .MuiFormLabel-root': {
          background: 'white',
          paddingRight: '4px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          span: {
            padding: 0,
          },
        },
      }}
      {...props}
    >
      <InputLabel
        id="demo-simple-select-autowidth-label"
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
      >
        {props.label}
      </InputLabel>
      <Select
        label={props.label}
        required
        value={props.value}
        onChange={props.onChange}
        MenuProps={{
          classes: { paper: classes.select },
          sx: {
            maxWidth: maxWidth,
          },
        }}
        startAdornment={<InputAdornment position="start"> </InputAdornment>}
        {...props}
      >
        {props.options?.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              color: '#616675',
              fontSize: '14px',
              fontWeight: 500,
              height: '52px',
              ':hover': {
                svg: {
                  fill: 'white !important',
                },
              },
            }}
          >
            <Tooltip title={option.label}>
              <div className="flex gap-3 w-95 items-center">
                {props.isEssay ? (
                  <Avatar
                    src={option.icon}
                    sx={{ width: '20px', height: '20px' }}
                  />
                ) : (
                  option.icon
                )}
                <div className="inline-block overflow-hidden whitespace-nowrap text-ellipsis">
                  {option.label}
                </div>
              </div>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
