import { auth, db } from '../config';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { APPLICATION_TEMPLATES, COURSE, PROFILE } from '../constants';
import { fireLogEvent } from './analytics';
import {
  ANALYTICS_EVENTS,
  COURSE_EVENTS,
  TEMPLATE_EVENTS,
} from '../../constants/firebaseAnalytics';
import { getLocalUser } from '../../utils/localStorage';
import { advanceDateByYear } from '../../utils/helper';
import { documentExists } from './auth';

const user = getLocalUser();

// Edit Course
export const updateCourse = (course) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, COURSE, course?.id), {
      ...course,
      updatedBy: auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        resolve(true);
        fireLogEvent(COURSE_EVENTS?.COURSE, {
          type: COURSE_EVENTS?.COURSE_UPDATED,
        });
      })
      .catch((error) => reject(false));
  });

// Edit Story Item
export const updateStoryItem = (storyItemDetails, collectionName) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, collectionName, storyItemDetails?.id), {
      ...storyItemDetails,
      updatedBy: storyItemDetails?.createdBy
        ? storyItemDetails?.createdBy
        : auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        resolve(storyItemDetails?.id);
        fireLogEvent(ANALYTICS_EVENTS[collectionName].LABEL, {
          type: ANALYTICS_EVENTS[collectionName].UPDATED,
        });
      })
      .catch((error) => {
        reject(false);
      });
  });

export const editProfile = (profile) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, PROFILE, profile?.id), {
      ...profile,
      updates: increment(1),
    })
      .then(() => resolve(true))
      .catch((error) => reject(false));
  });

export const editRequirementTemplate = (data, id) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, APPLICATION_TEMPLATES, id), {
      ...data,
      updatedAt: serverTimestamp(),
      updatedBy: auth?.currentUser?.uid ?? user?.uid,
      updateCount: increment(1),
    })
      .then(() => {
        resolve(id);
        fireLogEvent(TEMPLATE_EVENTS?.TEMPLATE, {
          type: TEMPLATE_EVENTS?.TEMPLATE_UPDATED,
        });
      })
      .catch((error) => {
        reject(false);
      });
  });

//Utility to update a document in a collection based on a search key and value
export const updateDocument = async (
  collectionName,
  searchKey,
  searchValue,
  key,
  payload,
  shouldReplace = false
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(searchKey, '==', searchValue)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('No matching documents found.');
      return;
    }

    // Assuming only one document matches the query
    const docId = querySnapshot.docs[0].id;

    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log('No such document!');
      return;
    }

    const currentData = docSnap.data();
    const currentFieldValue = currentData[key];

    let newValue;
    if (shouldReplace || currentFieldValue === undefined) {
      // Replace the value completely
      newValue = payload;
    } else if (Array.isArray(currentFieldValue) && Array.isArray(payload)) {
      // Spread and add to an array
      newValue = [...currentFieldValue, ...payload];
    } else if (
      typeof currentFieldValue === 'object' &&
      typeof payload === 'object'
    ) {
      // Spread and add to an object
      newValue = { ...currentFieldValue, ...payload };
    } else {
      // Replace if types don't match or spreading is not applicable
      newValue = payload;
    }

    await updateDoc(docRef, {
      [key]: newValue,
    });
    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

const getDocumentByKeyValue = async (collectionName, key, value) => {
  console.log(`Searching for document with ${key} = ${value}`);
  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef, where(key, '==', value));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null; // No matching document found
  }

  // Assuming there's only one document that matches the query
  const docData = querySnapshot.docs[0].data();
  const docId = querySnapshot.docs[0].id;
  console.log({ docId, docData });
  return { docId, docData };
};

const updateDocumentById = async (collectionName, docId, updateData) => {
  const docRef = doc(db, collectionName, docId);
  await updateDoc(docRef, updateData);
  console.log(`Document with ID ${docId} updated successfully.`);
};

// Utility to get document data from a collection based on a search key and value and update it
export const updateTeamMemberUIDByEmail = async (email, newUid) => {
  console.log(`Updating UID for team member with email ${email}`);
  const collectionName = 'teams';
  const key = 'studentEmail'; // Assuming this is a valid path for where clause

  const result = await getDocumentByKeyValue(collectionName, key, email);
  console.log(result);
  if (!result) {
    console.log(`No team member found with email ${email}`);
    return;
  }

  const { docId, docData } = result;

  // Update the team member's UID
  const updatedTeamMembers = docData.teamMembers.map((member) => {
    if (member.email === email) {
      return {
        ...member,
        uid: newUid,
      };
    }
    return member;
  });

  await updateDocumentById(collectionName, docId, {
    teamMembers: updatedTeamMembers,
  });
  console.log(`UID updated for team member with email ${email}`);
};

//  Utility to update all documents in a collection with a new value
export const updateAllDocuments = async (collectionName, updatedValue) => {
  const success = [];
  const failed = [];

  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    for (const documentSnapshot of querySnapshot.docs) {
      const docRef = doc(db, collectionName, documentSnapshot.id);

      try {
        await updateDoc(docRef, updatedValue);
        success.push({ id: documentSnapshot.id });
      } catch (error) {
        failed.push({ id: documentSnapshot.id });
        console.error('Error updating document: ', documentSnapshot.id, error);
      }
    }
  } catch (error) {
    console.error('Error getting documents: ', error);
  }

  return { success, failed };
};

export const advanceDateToYearInDocuments = async (collectionName) => {
  const success = [];
  const failed = [];

  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    for (const documentSnapshot of querySnapshot.docs) {
      const docRef = doc(db, collectionName, documentSnapshot.id);
      const data = documentSnapshot.data();

      // Assuming the field containing the array of objects is named 'rounds'
      if (Array.isArray(data.rounds)) {
        const updatedArray = data.rounds.map((item) => {
          if (item.applicationDueDate) {
            item.applicationDueDate = advanceDateByYear(
              item.applicationDueDate.toDate()
            );
          }
          if (item.applicationOpenDate) {
            item.applicationOpenDate = advanceDateByYear(
              item.applicationOpenDate.toDate()
            );
          }
          if (item.decisionDueDate) {
            item.decisionDueDate = advanceDateByYear(
              item.decisionDueDate.toDate()
            );
          }
          if (item.financialAidDueDate) {
            item.financialAidDueDate = advanceDateByYear(
              item.financialAidDueDate.toDate()
            );
          }
          return item;
        });

        try {
          await updateDoc(docRef, { rounds: updatedArray });
          console.log('Updated Document', documentSnapshot.id);
          success.push({ id: documentSnapshot.id });
        } catch (error) {
          failed.push({ id: documentSnapshot.id });
          console.error(
            'Error updating document: ',
            documentSnapshot.id,
            error
          );
        }
      } else {
        failed.push({
          id: documentSnapshot.id,
          error: 'rounds field is not an array',
        });
      }
    }
  } catch (error) {
    console.error('Error getting documents: ', error);
  }

  return { success, failed };
};

export const deleteMember = async (teamMembers, teamId, requestedMember) => {
  const updatedMembersList = teamMembers?.filter(
    (member) => member?.uid !== requestedMember
  );

  await updateDocument(
    'teams',
    'id',
    teamId,
    'teamMembers',
    updatedMembersList,
    true
  );
  await updateDocument('profile', 'uid', requestedMember, 'teams', [], true);
};
export const leaveTeam = async (teamId, requestedMember) => {
  const team = await documentExists('teams', 'id', teamId);

  if (!team) {
    return;
  }
  const teamData = team.data[0];
  const updatedMembersList = teamData.teamMembers?.filter(
    (member) => member?.uid !== requestedMember
  );

  await updateDocument(
    'teams',
    'id',
    teamId,
    'teamMembers',
    updatedMembersList,
    true
  );

  const profile = await documentExists('profile', 'uid', requestedMember);

  if (!profile) {
    return;
  }

  const profileData = profile.data[0];
  const updatedTeamsList = profileData.teams?.filter(
    (team) => team?.teamId !== teamId
  );

  await updateDocument(
    'profile',
    'uid',
    requestedMember,
    'teams',
    updatedTeamsList,
    true
  );
  return true;
};
