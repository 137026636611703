import { Avatar, Divider } from '@mui/material';
import Alert from '../shared/Alert';
import { RadioButton } from '../shared/RadioButton';
import { applicationTags, roundOptions } from '../../constants/other';
import { getFormattedDate, getUtilityLabel } from '../../utils/helper';
import Button from '../shared/Button';
import { Launch } from '@mui/icons-material';
import Tooltip from '../shared/Tooltip';

export const AddCollegeApplication = ({
  isValue = '',
  applicationData = {},
  onChange = () => {},
  roundData = [],
}) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div className="flex gap-6 items-center">
          <div className="flex gap-1">
            <div className="text-sm text-grey-900">Year :</div>
            <div className="text-sm text-grey-400">{applicationData?.year}</div>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              margin: '4px 0px',
              borderColor: '#D6D6D8',
            }}
          />
          <div className="flex gap-1">
            <div className="text-sm text-grey-900">Term :</div>
            <div className="text-sm text-grey-400 capitalize">
              {applicationData?.term}
            </div>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              margin: '4px 0px',
              borderColor: '#D6D6D8',
            }}
          />
          <div className="flex gap-1 items-center">
            <div className="text-sm text-grey-900">Round :</div>
            <Tooltip title={getUtilityLabel(roundData?.round, roundOptions)}>
              <div className="text-sm max-w-28 text-grey-400 truncate">
                {getUtilityLabel(roundData?.round, roundOptions) || '---'}
              </div>
            </Tooltip>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              margin: '4px 0px',
              borderColor: '#D6D6D8',
            }}
          />
          <div className="flex gap-1">
            <div className="text-sm text-grey-900">Application Due Date :</div>
            <div className="text-sm text-grey-400">
              {getFormattedDate(roundData?.applicationDueDate)}
            </div>
          </div>
        </div>
        <div className="flex gap-1 items-center">
          <Button
            variant="text"
            color="buttonLink"
            endIcon={
              <Launch
                sx={{
                  height: '14px',
                  width: '14px',
                }}
              />
            }
          >
            {applicationData?.collegeData?.collegeUrl}
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-sm text-lead-500">
          Select the Platform you Wish to Apply From
        </div>
        <div className="flex flex-col gap-4">
          {Object.keys(applicationData)?.length > 0 &&
            applicationData?.applicationPlatform?.map((item, index) => (
              <RadioButton
                key={index}
                label={
                  applicationTags.find(
                    (platform) => platform.applicationPlatform === item
                  )?.label
                }
                logo={
                  applicationTags.find(
                    (platform) => platform.applicationPlatform === item
                  )?.logo || (
                    <Avatar
                      src={applicationData?.collegeData?.collegeLogoURL}
                      aria-label="College Logo"
                      sx={{
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  )
                }
                checked={isValue === item || false}
                onClick={() => onChange(item)}
              />
            ))}
        </div>
      </div>
      <Alert>
        Universily will guide you in tracking your record according to the
        platform you wish to apply on. Modifying the platform later may lead to
        reorganizing your tracker again, Don’t worry, none of your data will be
        lost.
      </Alert>
    </div>
  );
};
