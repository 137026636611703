import { useEffect, useState } from 'react';
import Dialog from '../shared/Dialog';
import { AddCollegeApplication } from './AddCollegeApplication';

export const AddCollegeApplicationDialog = ({
  onContinue = () => {},
  onCancel = () => {},
  applicationData = {},
  ...props
}) => {
  const [isValue, setIsValue] = useState('');
  const [currentRound, setCurrentRound] = useState({});

  useEffect(() => {
    if (applicationData && Object.keys(applicationData)?.length > 0) {
      const roundData = applicationData?.rounds.find(
        (item) => item?.id === applicationData?.round
      );
      setCurrentRound(roundData);
      if (applicationData?.applicationPlatformName) {
        setIsValue(applicationData?.applicationPlatformName);
      }
    }
  }, [applicationData]);

  return (
    <Dialog
      onContinue={() => {
        onContinue({ applicationPlatformName: isValue });
        setIsValue('');
      }}
      onCancel={() => {
        onCancel();
        setIsValue('');
      }}
      {...props}
    >
      <AddCollegeApplication
        isValue={isValue}
        applicationData={applicationData}
        onChange={(data) => setIsValue(data)}
        roundData={currentRound}
      />
    </Dialog>
  );
};
