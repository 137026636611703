const Loader = ({ width = 600, height = 600 }) => {
  return (
    <lottie-player
      src="https://assets3.lottiefiles.com/packages/lf20_x62chJ.json"
      background="transparent"
      speed="1"
      style={{ width: width, height: height }}
      loop
      autoplay
    ></lottie-player>
  );
};

export default Loader;
