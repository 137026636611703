import { db, auth } from '../config';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { APPLICATION_TEMPLATES, COURSE } from '../constants';
import { fireLogEvent } from './analytics';
import {
  ANALYTICS_EVENTS,
  COURSE_EVENTS,
  TEMPLATE_EVENTS,
} from '../../constants/firebaseAnalytics';

// ADD COURSE
export const addCourse = (course) =>
  new Promise((resolve, reject) => {
    const newCourseRef = doc(collection(db, COURSE));
    setDoc(newCourseRef, {
      ...course,
      id: newCourseRef?.id,
      deleteDocument: false,
      createdAt: new Date(),
      createdBy: auth?.currentUser?.uid,
    })
      .then(() => {
        resolve(true);
        fireLogEvent(COURSE_EVENTS?.COURSE, {
          type: COURSE_EVENTS?.COURSE_CREATED,
        });
      })
      .catch((error) => reject(false));
  });

// ADD STORY ITEM
export const addStoryItem = (
  storyDetails,
  collectionName,
  isOverrideCreatedBy = false
) =>
  new Promise((resolve, reject) => {
    const newStoryItemRef = doc(collection(db, collectionName));
    setDoc(newStoryItemRef, {
      ...storyDetails,
      id: newStoryItemRef?.id,
      deleteDocument: false,
      createdAt: serverTimestamp(),
      ...(isOverrideCreatedBy
        ? {}
        : {
            createdBy: storyDetails?.createdBy
              ? storyDetails.createdBy
              : auth?.currentUser?.uid,
          }),
    })
      .then(() => {
        resolve(newStoryItemRef?.id);
        fireLogEvent(ANALYTICS_EVENTS[collectionName].LABEL, {
          type: ANALYTICS_EVENTS[collectionName].CREATED,
        });
      })
      .catch((error) => reject(false));
  });

export const addCollaborator = (
  collectionName,
  documentId,
  teamMemberId,
  isRemove = false
) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, collectionName, documentId), {
      collaborators: isRemove
        ? arrayRemove(teamMemberId)
        : arrayUnion(teamMemberId),
      updatedBy: auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        resolve(documentId);
        fireLogEvent(ANALYTICS_EVENTS[collectionName].LABEL, {
          type: ANALYTICS_EVENTS[collectionName].UPDATED,
        });
      })
      .catch(() => reject(false));
  });

// Add Requirement Template
export const addNewRequirementTemplate = (data) =>
  new Promise((resolve, reject) => {
    const collectionRef = doc(collection(db, APPLICATION_TEMPLATES));
    setDoc(collectionRef, {
      id: collectionRef?.id,
      ...data,
      deleteDocument: false,
      createdAt: new Date(),
      createdBy: auth?.currentUser?.uid,
      creatorEmail: auth?.currentUser?.email,
    })
      .then(() => {
        resolve(collectionRef?.id);
        fireLogEvent(TEMPLATE_EVENTS?.TEMPLATE, {
          type: TEMPLATE_EVENTS?.TEMPLATE_CREATED,
        });
      })
      .catch(() => reject(false));
  });
