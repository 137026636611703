import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import ChangePassword from '../components/myAccount/ChangePassword';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { auth } from '../firebase/config';
import useProfileStore from '../stores/ProfileStore';
import { firebaseQueryOperators } from '../firebase/queryBuilder';
import { useStoryItemListener } from '../hooks';
import { SUBSCRIPTION } from '../firebase/constants';
import { firebaseDataType } from '../constants/other';

const MyAccountContainer = ({ profileUserId }) => {
  const profileStore = useProfileStore();
  const [oldPassword, setOldPassword] = useState('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [isOldPasswordCorrect, setIsOldPasswordCorrect] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');

  const {
    // eslint-disable-next-line no-unused-vars
    dataCount: subscriptionCount,
    data: subscriptionData,
    isFetching: isFetchingSubscriptionData,
  } = useStoryItemListener(SUBSCRIPTION, firebaseDataType.STUDENT_DATA, [
    {
      property: 'createdBy',
      operator: firebaseQueryOperators.EQUAL_TO,
      value: profileUserId,
    },
  ]);

  useEffect(() => {
    profileStore.setSubscriptions(subscriptionData && subscriptionData[0]);
    //eslint-disable-next-line
  }, [subscriptionData, isFetchingSubscriptionData]);

  const verifyOldPassword = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    var credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      oldPassword
    );
    if (!isOldPasswordCorrect) {
      reauthenticateWithCredential(user, credential)
        .then((res) => {
          setIsOldPasswordCorrect(true);
          setError('');
        })
        .catch((error) => {
          setIsOldPasswordCorrect(false);
          setError('Incorrect password');
        });
    } else if (isOldPasswordCorrect && newPassword !== '') {
      await updatePassword(auth.currentUser, newPassword);
      profileStore.setIsChangePassword(false);
      setIsOldPasswordCorrect(false);
      setSuccessSnackbarOpen(true);
    }
  };

  return (
    <>
      <SuccessSnackbar
        message={'Your password was changed.'}
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessSnackbarOpen(false);
        }}
      />
      <ChangePassword
        open={profileStore.isChangePassword}
        onContinue={(e) => {
          verifyOldPassword(e);
        }}
        error={error}
        setOldPassword={setOldPassword}
        setNewPassword={setNewPassword}
        isOldPasswordCorrect={isOldPasswordCorrect}
        onCancel={() => {
          profileStore.setIsChangePassword(false);
          setError('');
        }}
      />
    </>
  );
};

export default MyAccountContainer;
