import { Avatar } from '@mui/material';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { displayTextWithLineBreaks } from '../../utils/helper';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ChatBubble = ({
  receiverAvatar = '',
  receiverName = '',
  text,
  direction,
  firstOfType = '',
  lastOfType = '',
  timeStamp = '',
  isPrompt = false,
  isAI = false,
  onClickPrompt = () => {},
}) => {
  dayjs.extend(localizedFormat);
  const chatMessageRef = useRef();
  useEffect(() => {
    if (chatMessageRef.current) {
      chatMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, []);
  function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (error) {
      return false;
    }
  }

  function parseText(text) {
    // Split the text by spaces to isolate potential URLs.
    return text.split(' ').map((word, index) => {
      if (isValidUrl(word)) {
        // If the word is a valid URL, render it as a link.
        return (
          <>
            <a
              key={index}
              href={word}
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {word}
            </a>
            &nbsp;
          </>
        );
      } else {
        // Otherwise, return the word as plain text.
        return word + ' ';
      }
    });
  }

  function TextDisplay({ text, isAI }) {
    return (
      <div className="">
        {text && isAI ? (
          <Markdown children={text} remarkPlugins={[remarkGfm]} />
        ) : text ? (
          parseText(text)
        ) : (
          ''
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col" ref={chatMessageRef}>
      {direction === 'left' && firstOfType && (
        <div className="flex items-center gap-2 mb-px mt-5">
          <Avatar
            src={receiverAvatar}
            sx={{
              height: '25px',
              width: '25px',
            }}
          />
          <div className="flex w-[274px]">
            <div className="text-sm text-grey-900 font-medium">
              {receiverName}
            </div>
            {/*<div className="text-[10px] text-grey-300 ml-auto flex">
              {dayjs(timeStamp).format('h:mm A').toString()}
            </div>*/}
          </div>
        </div>
      )}
      <div
        className={`inline-block h-fit w-fit max-w-full ${
          isPrompt
            ? 'bg-brand-blue-200 text-cream-50 self-end cursor-pointer'
            : direction === 'right'
            ? 'bg-brand-blue-500 text-cream-50 self-end'
            : 'bg-cream-75 text-grey-800 ml-8 self-start'
        } text-xs p-2 rounded-b-lg  ${
          firstOfType
            ? `${
                direction !== 'right'
                  ? 'rounded-tl-0 rounded-tr-lg'
                  : 'rounded-tl-lg rounded-tr-0'
              }`
            : 'rounded-lg'
        } ${lastOfType && direction === 'left' ? 'mb-5' : 'mb-1'}`}
        onClick={() => (isPrompt ? onClickPrompt() : null)}
      >
        <div className="inline pr-2">
          {text ? (
            isPrompt ? (
              text
            ) : (
              <TextDisplay isAI={isAI} text={text} />
            )
          ) : (
            ''
          )}
        </div>
        {timeStamp ? (
          <div
            className={`inline-block float-right text-[10px] w-max whitespace-nowrap text-grey-300 ml-auto ${
              direction === 'right' ? 'text-cream-200' : 'text-cream-500'
            }`}
          >
            {dayjs(timeStamp).format('h:mm A').toString()}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatBubble;
