import { Avatar, Divider, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LaunchRounded from '@mui/icons-material/Launch';
import MyAccountDetailsForm from './MyAccountDetailsForm';
import useProfileStore from '../../stores/ProfileStore';
import { ReactComponent as GradeAccount } from '../../assets/svgs/gradeAccount.svg';
import { ReactComponent as CollegeAccount } from '../../assets/svgs/collegeAccount.svg';
import { ReactComponent as EmailAccount } from '../../assets/svgs/emailAccount.svg';
import { ReactComponent as PhoneAccount } from '../../assets/svgs/phoneAccount.svg';
import { ReactComponent as AddressAccount } from '../../assets/svgs/addressAccount.svg';
import Button from '../shared/Button';
const MyAccountDetails = ({
  onSave = () => {},
  profileData = {},
  isFormOpen = false,
  profileLogin = {},
  setIsFormOpen = () => {},
  linkGoogleAccount = () => {},
  uploadProfilePic = () => {},
  isParent,
  isViewOnly = true,
}) => {
  const profileStore = useProfileStore();
  const gradeItems = [
    {
      grade: '9',
      label: 'Freshman | Grade 9',
    },
    {
      grade: '10',
      label: 'Sophomore | Grade 10',
    },
    {
      grade: '11',
      label: 'Junior | Grade 11',
    },
    {
      grade: '12',
      label: 'Senior | Grade 12',
    },
  ];

  return (
    <div className="h-full w-full bg-white overflow-auto shadow-md border-t-[20px] rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl rounded-br-2xl border-indigo-100 py-5">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-8">
          <div className="flex items-start justify-between sm:px-8">
            <div className="block sm:hidden" />
            <div className="flex flex-col sm:flex-row sm:items-center justify-center gap-4">
              <div className="flex items-center justify-center h-36 w-36 rounded-full bg-gray-50 border-[3px] border-white text-grey-600 text-[56px] font-medium relative">
                {profileData?.photoURL ? (
                  <Avatar
                    src={profileData.photoURL}
                    sx={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                ) : (
                  <div>{profileData?.firstName?.charAt(0)}</div>
                )}
                {isViewOnly && (
                  <div className="bg-gray-50 border flex border-white h-fit w-fit rounded-full absolute bottom-0 left-[100px]">
                    <IconButton aria-label="upload picture" component="label">
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={({ target }) => {
                          uploadProfilePic(target.files[0]);
                        }}
                      />
                      <PhotoCameraIcon className="cursor-pointer" />
                    </IconButton>
                  </div>
                )}
              </div>
              <div className="flex justify-center text-primary-black text-2xl font-bold">
                {profileData?.profileName}
              </div>
            </div>
            {!isFormOpen && isViewOnly && (
              <IconButton
                aria-label="Edit"
                onClick={() => {
                  setIsFormOpen(true);
                  // profileStore.setIsEditingProfile(true);
                  // profileStore.setEditProfileId(profileData?.id);
                }}
              >
                <EditIcon className="cursor-pointer" />
              </IconButton>
            )}
          </div>
          {!isFormOpen && (
            <div className="flex flex-col px-4 sm:px-10 gap-8">
              <div className="border border-cream-100 py-6 px-4 flex  flex-col sm:flex-row rounded-2xl ">
                <div className="flex sm:items-center flex-col sm:flex-row gap-5">
                  {profileData?.grade && (
                    <div className="flex items-center gap-4">
                      <GradeAccount />
                      <div className="flex items-center">
                        <div> Grade:</div>
                        <div className="text-grey-400 ml-1">
                          {`${
                            gradeItems
                              .find(
                                (grade) => grade.grade === profileData?.grade
                              )
                              ?.label.split(' ')[0] || ''
                          } :`}
                        </div>
                        <div className="text-grey-400 ml-1">
                          {profileData?.grade || '---'}
                        </div>
                      </div>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          borderColor: '#BABAAE',
                          opacity: '20%',
                        }}
                      />
                    </div>
                  )}
                  <div className="flex sm:items-center gap-0 sm:gap-4 flex-col sm:flex-row">
                    <div className="flex items-center gap-4">
                      <CollegeAccount />
                      <div>High school name, City/State :</div>
                    </div>
                    <div className="flex sm:items-center flex-col sm:flex-row gap-2 pl-12 sm:pl-0 break-all">
                      <div className="text-grey-400">
                        {profileData?.schoolName || '---'}
                      </div>
                      <Button
                        variant="text"
                        color="buttonLink"
                        href={profileData?.highschoolLink}
                        className="!p-0"
                        target="_blank"
                        disabled={!profileData?.highschoolLink}
                        endIcon={
                          <LaunchRounded
                            sx={{
                              height: '14px',
                              width: '14px',
                            }}
                          />
                        }
                      >
                        {profileData?.highschoolLink}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-cream-100 py-6 px-4 flex flex-col gap-8 rounded-2xl ">
                <div className="flex flex-col sm:flex-row sm:items-center gap-6">
                  <div className="flex items-center gap-4">
                    <EmailAccount />
                    <div className="flex flex-col sm:flex-row">
                      <div> Email:</div>
                      <div className="text-grey-400 ml-1">
                        {profileData?.email || '---'}
                      </div>
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderColor: '#BABAAE',
                      opacity: '20%',
                    }}
                  />
                  <div className="flex items-center gap-4">
                    <PhoneAccount />
                    <div className="flex items-center">
                      <div>Phone Number :</div>
                      <div className="text-grey-400 ml-1">
                        {profileData?.phoneNumber || '---'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex  items-center gap-4">
                  <AddressAccount className="self-start" />
                  <div className="flex sm:items-center sm:flex-row">
                    <p className="self-start">Mailing Address :</p>
                    <div className="text-grey-400 ml-1">
                      <p>
                        {profileData?.address?.line1
                          ? `${profileData?.address?.line1}`
                          : '---'}
                      </p>
                      <p>
                        {profileData?.address?.line2
                          ? `${profileData?.address?.line2}`
                          : null}
                      </p>
                      <p>
                        {profileData?.address?.city &&
                        profileData?.address?.state &&
                        profileData?.address?.postal_code
                          ? `${profileData?.address?.city} ${profileData?.address?.state} ${profileData?.address?.postal_code}`
                          : null}
                      </p>
                      <p>
                        {profileData?.address?.country
                          ? `${profileData?.address?.country}`
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isFormOpen && (
        <div className="py-6 px-10">
          <MyAccountDetailsForm
            open={isFormOpen}
            isParent={isParent}
            firstName={profileData?.firstName}
            lastName={profileData?.lastName}
            phoneNumber={profileData?.phoneNumber}
            email={profileData?.email}
            schoolName={profileData?.schoolName}
            address={profileData?.address}
            highschoolLink={profileData?.highschoolLink}
            grade={profileData?.grade}
            receiveNotifications={profileData?.receiveNotifications}
            onSave={onSave}
            loggedInType={profileLogin?.loggedInType}
            onClose={() => setIsFormOpen(false)}
            onChangePassword={() => profileStore.setIsChangePassword(true)}
            linkGoogleAccount={linkGoogleAccount}
          />
        </div>
      )}
    </div>
  );
};

export default MyAccountDetails;
