import axios from 'axios';
import config from './urlConfig';

const axiosApi = axios.create({
  baseURL: config?.backendUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const stripeApiHandler = async (url, data) => {
  try {
    const res = await axiosApi.post(url, data);
    if (res) return res;
  } catch (err) {}
};
