import styled from "@emotion/styled";
import { Menu as MENU } from "@mui/material";

const Menu = styled(MENU)(() => ({
  ".MuiPaper-root": {
    border: "1px solid #F4F4F4",
    marginTop: 4,
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
  ".MuiMenu-list": {
    padding: 8,
  },
}));

export default Menu;
