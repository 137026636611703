import DeleteDialog from '../shared/DeleteDialog';

const DeleteApplicationsDialog = ({ ...props }) => {
  return (
    <DeleteDialog title="Delete College" {...props}>
      <div className="text-[#2C303C] font-medium">
        Are you sure you want to delete the college?
      </div>
      <div className="text-lead-500 text-[13px]">
        You will lose the College related requirements.
      </div>
    </DeleteDialog>
  );
};

export default DeleteApplicationsDialog;
