import Person from '@mui/icons-material/Person';
import { Avatar } from '@mui/material';
import useProfileStore from '../../stores/ProfileStore';
import TeamMemberAvatarGroup from './TeamMemberAvatarGroup';
import Tooltip from './Tooltip';

export const avatarTitle = (item) => {
  return (
    <div className="flex gap-2 text-xs items-center">
      <Avatar
        src={item?.photoURL}
        aria-label={item?.firstName + ' ' + item?.lastName}
        sx={{
          height: '16px',
          width: '16px',
        }}
      />
      <div>
        {item?.firstName} {item?.lastName}
      </div>
    </div>
  );
};
const TeamMembersGroup = ({ isParent = false, reviewers = [], onClick }) => {
  const profileStore = useProfileStore();
  const lastAvatarTitle = (
    <div className="flex flex-col gap-2">
      {reviewers?.slice(-(reviewers.length - 2))?.map((item) => (
        <li className="flex gap-2 text-xs items-center" key={item?.id}>
          <Avatar
            src={item?.photoURL}
            aria-label={item?.firstName + ' ' + item?.lastName}
            sx={{
              height: '16px',
              width: '16px',
            }}
          />
          <div>
            {item?.firstName} {item?.lastName}
          </div>
        </li>
      ))}
    </div>
  );

  return (
    <div className="flex justify-end relative">
      <TeamMemberAvatarGroup>
        {reviewers?.map((reviewer, index) => (
          <Tooltip title={avatarTitle(reviewer)} key={index}>
            <Avatar
              onClick={() => {
                if (onClick) {
                  onClick();
                }
              }}
              src={reviewer?.photoURL}
            />
          </Tooltip>
        ))}
      </TeamMemberAvatarGroup>
      {reviewers?.length > 2 && (
        <Tooltip title={lastAvatarTitle}>
          <div className="h-7 w-7 text-grey-600 bg-grey-50 border-2 border-white text-xs font-medium absolute flex items-center justify-center top-px rounded-full">
            +{reviewers?.length - 2}
          </div>
        </Tooltip>
      )}
      {reviewers?.length === 0 && (
        <Tooltip
          title={`${
            !isParent
              ? 'Once you add your Team Members they will appear here'
              : 'Student is yet to add team member'
          }`}
        >
          <div className="h-7 w-7 text-grey-600 bg-grey-50 border-2 border-white flex items-center justify-center top-px rounded-full">
            <Person
              sx={{
                height: '18px',
              }}
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default TeamMembersGroup;
