import { Checkbox as CHECKBOX } from '@mui/material';
import { styled } from '@mui/system';

const StyledCheckbox = styled(CHECKBOX)(() => ({
  padding: 0,
  color: '#22252D',
  '&.Mui-checked': {
    color: '#22252D',
  },
  '& .MuiSvgIcon-root': {
    height: '18px',
    width: '18px',
  },
  '&.Mui-disabled': {
    color: 'rgba(34, 37, 45, 0.34) !important',
  },
}));

const label = { inputProps: { 'aria-label': 'Checkbox' } };

export const Checkbox = (props) => {
  return <StyledCheckbox {...label} {...props} />;
};
