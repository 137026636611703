import { useState } from 'react';
import Dialog from '../shared/Dialog';
import InviteAFriend from './InviteAFriend';
import useInviteAFriendStore from '../../stores/InviteAFriendStore';

const InviteAFriendPopup = ({
  onContinue = () => {},
  onCancel = () => {},
  disabled = false,
  setDisabled = () => {},
  isParent = false,
  ...args
}) => {
  const [isFirstNameEmpty, setIsFirstNameEmpty] = useState(false);
  const [isLastNameEmpty, setIsLastNameEmpty] = useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [isRoleEmpty, setIsRoleEmpty] = useState(false);
  const [teamMemberData, setTeamMemberData] = useState({});
  const inviteStore = useInviteAFriendStore();
  const onChange = (teamMember) => {
    setTeamMemberData(teamMember);
  };

  return (
    <Dialog
      isHeight={false}
      continueText={!inviteStore.isReferingAFriend ? 'Invite' : 'Refer'}
      title={
        !inviteStore.isReferingAFriend ? 'Invite a Student' : 'Refer a Friend'
      }
      disabled={disabled}
      onContinue={() => {
        if (teamMemberData?.firstName === '') setIsFirstNameEmpty(true);
        else setIsFirstNameEmpty(false);
        if (teamMemberData?.lastName === '') setIsLastNameEmpty(true);
        else setIsLastNameEmpty(false);
        if (teamMemberData?.email === '') setIsEmailEmpty(true);
        else setIsEmailEmpty(false);
        if (teamMemberData?.role === '') setIsRoleEmpty(true);
        else setIsRoleEmpty(false);
        if (
          teamMemberData?.firstName !== '' &&
          teamMemberData?.lastName !== '' &&
          teamMemberData?.email !== '' &&
          teamMemberData.role !== ''
        ) {
          if (disabled) return;

          setDisabled(true);
          onContinue(teamMemberData);
        }
      }}
      onCancel={() => {
        setIsRoleEmpty(false);
        setIsLastNameEmpty(false);
        setIsFirstNameEmpty(false);
        setIsEmailEmpty(false);
        setDisabled(false);
        onCancel();
      }}
      sx={{
        '.MuiDialog-container ': {
          '.MuiPaper-root ': {
            '.MuiButton-contained': {
              cursor: `${disabled ? 'not-allowed !important' : ''}`,
              pointerEvents: `${disabled ? 'none !important' : ''}`,
            },
          },
        },
      }}
      //   onCancel={() => {
      //     onCancel();
      //     setIsFirstNameEmpty(false);
      //     setIsLastNameEmpty(false);
      //     setIsEmailEmpty(false);
      //   }}
      {...args}
    >
      {/* <AddTeamMember
        firstName={memberData.firstName}
        lastName={memberData.lastName}
        email={memberData.email}
        role={memberData.role}
        alertMessage={getAlertMessage()}
        onChange={onChange}
        isFirstNameEmpty={isFirstNameEmpty}
        isLastNameEmpty={isLastNameEmpty}
        isEmailEmpty={isEmailEmpty}
        existingTeamMembers={existingTeamMembers}
      /> */}
      <InviteAFriend
        onChange={onChange}
        isFirstNameEmpty={isFirstNameEmpty}
        isLastNameEmpty={isLastNameEmpty}
        isEmailEmpty={isEmailEmpty}
        isRoleEmpty={isRoleEmpty}
        isParent={isParent}
      />
    </Dialog>
  );
};

export default InviteAFriendPopup;
