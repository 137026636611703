import {
  collection,
  doc,
  where,
  query,
  setDoc,
  getDocs,
} from 'firebase/firestore';
import { localKeys } from '../../constants/localStorage';
import { apiHandler } from '../axios';
import { auth, db } from '../config';
import { PROFILE, REVIEWER, TEAMS } from '../constants';
// import useStudentStore from '../../stores/StudentStore';

// Get the user data thorough mail
export const getUserData = (email) =>
  new Promise((resolve) => {
    // const studentStore = useStudentStore();
    const getUserProfileData = query(
      collection(db, PROFILE),
      where('deleteDocument', '==', false),
      where('email', '==', email)
      // where('email', '==', studentStore?.email ? studentStore?.email : email)
    );
    let fetchedProfiles = [];
    getDocs(getUserProfileData)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedProfile = {
            id: document.id,
            ...document.data(),
          };
          fetchedProfiles.push(fetchedProfile);
        });
        fetchedProfiles && fetchedProfiles.length > 0
          ? resolve(fetchedProfiles[0])
          : resolve('');
      })
      .catch((error) => resolve(false));
  });

// Add new Reviewer
export const addNewReviewer = (reviewer) =>
  new Promise((resolve, reject) => {
    const reviewerRef = doc(collection(db, REVIEWER));
    setDoc(reviewerRef, {
      ...reviewer,
      id: reviewerRef.id,
      createdAt: new Date(),
      createdBy: auth?.currentUser?.uid,
      users: [
        {
          userId: auth?.currentUser?.uid,
        },
      ],
    })
      .then(() => resolve(true))
      .catch((error) => reject(false));
  });

// Add New Profile
export const addNewProfile = (profile) =>
  new Promise((resolve, reject) => {
    const newProfileRef = doc(collection(db, PROFILE));
    setDoc(newProfileRef, {
      ...profile,
      id: newProfileRef.id,
      createdAt: new Date(),
      createdBy: auth?.currentUser?.uid,
      users: [
        {
          userId: auth?.currentUser?.uid,
        },
      ],
    })
      .then(() => resolve(true))
      .catch((error) => reject(false));
  });

// Function to check if a document exists in any collection
export const documentExists = async (collectionName, key, value) => {
  const q = query(collection(db, collectionName), where(key, '==', value));
  const querySnapshot = await getDocs(q);
  return {
    data: querySnapshot.docs.map((doc) => doc.data()),
    exists: !querySnapshot.empty,
  };
};

// Add New Team
export const addNewTeam = (team, parentData) =>
  new Promise(async (resolve, reject) => {
    console.log('team', team);

    const newTeamRef = doc(collection(db, TEAMS));
    setDoc(newTeamRef, { ...team, id: newTeamRef.id })
      .then((data) =>
        resolve({
          id: newTeamRef.id,
          ...data,
        })
      )
      .catch((error) => {
        reject(new Error('Error while adding new team'));
      });
  });

// Sign Out User
export const signOut = async () => {
  apiHandler.defaults.headers.common.Authorization = '';
  await localStorage.removeItem(localKeys.AUTH);
  await localStorage.removeItem(localKeys.GOOGLE_OAUTH);
  await localStorage.removeItem(localKeys.GOOGLE_OAUTH_DATA);
  await auth.signOut();
};
