import { useEffect, useState } from 'react';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { addStoryItemTypes } from '../constants/other';
import { teamUniversilyEmail } from '../constants/staticData';
import { ApiRoutes } from '../firebase/apis';
import { axiosPost } from '../firebase/axios';
import { addStoryItem } from '../firebase/services/addServices';
import { getProfileDataByEmail } from '../firebase/services/user';
import useChatStore from '../stores/ChatStore';
import useProfileStore from '../stores/ProfileStore';
import config from '../utils/urlConfig';
import MyStudentPage from '../components/myStudent/MyStudentPage';
import useMyStudentStore from '../stores/MyStudentStore';
import AddStudentDialog from '../components/shared/AddStudentDialog';
import { INVITE_KEYS, userTypeKeywords } from '../constants/keywords';
import { getStoryItem } from '../firebase/services/getServices';
import { firebaseQueryOperators } from '../firebase/queryBuilder';
import { encodeLocalData } from '../utils/localStorage';
import RemoveStudentDialog from '../components/myStudent/RemoveStudentDialog';
import { deleteStoryItem } from '../firebase/services/deleteServices';
import { updateStoryItem } from '../firebase/services/updateServices';
import useActivityStore from '../stores/ActivityStore';
import useHonorStore from '../stores/HonorStore';
import useNicheStore from '../stores/NicheStore';
import useCourseStore from '../stores/CourseStore';

const MyStudentContainer = () => {
  const myStudentStore = useMyStudentStore();
  const activityStore = useActivityStore();
  const courseStore = useCourseStore();
  const honorStore = useHonorStore();
  const nicheStore = useNicheStore();
  const profileStore = useProfileStore();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState({
    open: false,
    message: '',
  });
  const [disabled, setDisabled] = useState(false);
  const [deleteSnackbar, setDeleteSnackbar] = useState(false);

  const unlinkReviewerFromUtility = (id) => {
    activityStore.activities.map(async (activity) => {
      if (activity?.reviewers.includes(id)) {
        await updateStoryItem(
          {
            ...activity,
            reviewers: activity.reviewers.filter((reviewer) => reviewer !== id),
          },
          addStoryItemTypes.ACTIVITY
        );
      }
    });
    courseStore.courses.map(async (course) => {
      if (course?.reviewers.includes(id)) {
        await updateStoryItem(
          {
            ...course,
            reviewers: course.reviewers.filter((reviewer) => reviewer !== id),
          },
          addStoryItemTypes.COURSE
        );
      }
    });
    honorStore.honors.map(async (honor) => {
      if (honor?.reviewers.includes(id)) {
        await updateStoryItem(
          {
            ...honor,
            reviewers: honor.reviewers.filter((reviewer) => reviewer !== id),
          },
          addStoryItemTypes.HONOR
        );
      }
    });
    nicheStore.niches.map(async (niche) => {
      if (niche?.reviewers.includes(id)) {
        await updateStoryItem(
          {
            ...niche,
            reviewers: niche.reviewers.filter((reviewer) => reviewer !== id),
          },
          addStoryItemTypes.NICHE
        );
      }
    });
  };

  const sendInviteToReviewer = async (teamMember) => {
    const params = {
      ...teamMember,
    };
    const result = await axiosPost(ApiRoutes?.INVITE_USER, params);
    if (result.data) {
      setSuccessSnackbarOpen({
        open: true,
        message: `Invite sent to ${
          teamMember?.studentName || teamMember?.reviewerEmail
        }`,
      });
    }
    return result;
  };

  const teamMemberActions = async (teamMemberDetails) => {
    const teamMember = {
      ...teamMemberDetails,
      reviewerEmail: teamMemberDetails?.email.trim(),
      inviteLink: `${
        config.getSiteBaseUrl
      }/signUp?studentMail=${teamMemberDetails.email.trim()}&type=${
        INVITE_KEYS.STUDENT
      }&role=${userTypeKeywords.STUDENT}&invitee=${
        profileStore.profile?.uid
      }&reviewerRole=${profileStore.profile?.profileType}`,
      templateName: 'request_join_new_student',
      firstName: profileStore.profile?.firstName,
      lastName: profileStore.profile?.lastName,
      reviewerRole: profileStore.profile?.profileType,
    };
    if (teamMember.email) {
      const res = await getStoryItem(addStoryItemTypes.PROFILE, [
        {
          property: 'email',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: teamMember.email,
        },
      ]);
      if (res && res?.length > 0) {
        teamMember.inviteLink = `${
          config.getSiteBaseUrl
        }/acceptInvitation?studentMail=${encodeLocalData(
          teamMemberDetails.email
        )}&type=${encodeLocalData(INVITE_KEYS.STUDENT)}&role=${encodeLocalData(
          userTypeKeywords.STUDENT
        )}&invitee=${encodeLocalData(
          profileStore.profile?.uid
        )}&reviewerRole=${encodeLocalData(profileStore.profile?.profileType)}`;
        teamMember.templateName = 'request_join_existing_student';
        teamMember.studentName = res.at(0)?.firstName;
      }
      if (
        !myStudentStore.myStudents.some(
          (item) => item.email === teamMember.email
        )
      ) {
        const result = await sendInviteToReviewer(teamMember);

        if (result?.status) {
          await addStoryItem(
            {
              reviewerId: profileStore.profile?.uid,
              revieweeId: res?.at(0)?.uid || '',
              createdBy: res?.at(0)?.uid || '',
              isRegistered: true,
              isAccepted: true,
              isStudentRegistered: !!res?.at(0)?.uid,
              isStudentAccepted: false,
              studentFirstName: res?.at(0)?.firstName || '',
              studentLastName: res?.at(0)?.lastName || '',
              studentEmail: teamMember?.reviewerEmail,
              role: profileStore.profile?.profileType,
              email: profileStore.profile?.email,
              firstName: profileStore.profile?.firstName,
              lastName: profileStore.profile?.lastName,
            },
            addStoryItemTypes.REVIEWER,
            true
          );
        }
        myStudentStore.setIsAddStudent(false);
      }
      setDisabled(false);
    }
  };

  const onDelete = async () => {
    setDeleteSnackbar(true);
    myStudentStore.setIsRemoving(true);
  };

  const hardDeleteTeamMember = async () => {
    const id = myStudentStore.removeStudentId;
    if (id) {
      await unlinkReviewerFromUtility(id);
      await deleteStoryItem(id, addStoryItemTypes.REVIEWER);
      myStudentStore.setIsRemoving(false);
    }
  };

  useEffect(() => {
    if (deleteSnackbar) {
      hardDeleteTeamMember();
    }
    //eslint-disable-next-line
  }, [deleteSnackbar]);

  return (
    <>
      <SuccessSnackbar
        message={successSnackbarOpen.message}
        open={successSnackbarOpen.open}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessSnackbarOpen({
            open: false,
            message: '',
          });
        }}
      />

      <SuccessSnackbar
        open={deleteSnackbar}
        autoHideDuration={6000}
        message="The team member was deleted."
        actionName="Undo"
        onAction={() => {
          myStudentStore.setIsRemoveStudentId(null);
          setDeleteSnackbar(false);
          myStudentStore.setIsDeletingSnackbar(false);
        }}
        onClose={() => {
          setDeleteSnackbar(false);
        }}
      />
      <AddStudentDialog
        existingTeamMembers={myStudentStore.myStudents.map(
          (teamMember) => teamMember.studentEmail
        )}
        disabled={disabled}
        setDisabled={setDisabled}
        open={myStudentStore.isAddStudent}
        onContinue={async (teamMember) => teamMemberActions(teamMember)}
        onCancel={() => {
          setDisabled(false);
          myStudentStore.setIsAddStudent(false);
        }}
      />
      <RemoveStudentDialog
        open={myStudentStore.isRemoving}
        onCancel={() => {
          myStudentStore.setIsRemoving(false);
          myStudentStore.setIsRemoveStudentId(null);
        }}
        onContinue={async () => {
          await onDelete();
          myStudentStore.setIsRemoving(false);
        }}
      />
    </>
  );
};

export default MyStudentContainer;
