import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { useState } from 'react';
import Dialog from '../shared/Dialog';

const ChangePassword = ({
  setOldPassword = () => {},
  setNewPassword = () => {},
  error = '',
  isOldPasswordCorrect = false,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Dialog title="Change Password" {...props}>
      {!isOldPasswordCorrect && (
        <FormControl sx={{ mt: 1 }} className="w-full" variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Old Password*
          </InputLabel>
          <OutlinedInput
            className="w-full"
            error={error !== ''}
            helperText="Incorrect password"
            id="filled-adornment-password"
            type={showPassword ? 'text' : 'password'}
            label="Old Password*"
            onChange={(event) => setOldPassword(event.target.value)}
            placeholder="Enter your New Password"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                '& legend': {
                  paddingRight: '8px',
                },
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      )}
      {isOldPasswordCorrect && (
        <FormControl sx={{ mt: 1 }} className="w-full" variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            New Password*
          </InputLabel>
          <OutlinedInput
            className="w-full"
            error={error !== ''}
            helperText="Incorrect password"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                '& legend': {
                  paddingRight: '8px',
                },
              },
            }}
            id="filled-adornment-password"
            type={showPassword ? 'text' : 'password'}
            label="Old Password*"
            onChange={(event) => setNewPassword(event.target.value)}
            placeholder="Please re-enter your Old Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      )}
    </Dialog>
  );
};

export default ChangePassword;
