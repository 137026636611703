import { checkForPremiumAccess } from './checkForPremiumAccess';

export const canCreateNewUtilities = (profile) => {
  const isLicenseActive =
    profile?.email === 'team@universily.com' ||
    profile?.email === 'admin@universily.com' ||
    profile?.email === 'askuni@universily.com'
      ? true
      : checkForPremiumAccess(profile?.licenseData?.subscriptionStatus);
  return isLicenseActive;
};
