import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import Dialog from '../shared/Dialog';
import SelectTeamMembers from '../shared/SelectTeamMembers';

const CopyCoreEssayDialog = ({
  essayName = '',
  reviewers = [],
  onContinue = () => {},
  onCancel = () => {},
  teamDataReviewers = [],
  ...props
}) => {

  const [essayData, setEssayData] = useState({
    essayName,
    sharedWith: teamDataReviewers.length > 0 ? teamDataReviewers : teamDataReviewers,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [isNameEmpty, setIsNameEmpty] = useState(false);

  useEffect(() => {
    if (essayName) {
      setEssayData({
        ...essayData,
        essayName,
        sharedWith: teamDataReviewers.length > 0 ? teamDataReviewers : teamDataReviewers,
      });
    }
    //eslint-disable-next-line
  }, [essayName]);

  return (
    <Dialog
      continueText="Make a Copy"
      onContinue={async () => {
        if (essayData.essayName !== '') {
          setIsLoading(true);
          await onContinue(essayData);
          setIsLoading(false);
          setEssayData({
            essayName: '',
            sharedWith: [],
          });
          setIsNameEmpty(false);
        } else {
          setIsNameEmpty(true);
        }
      }}
      onCancel={() => {
        onCancel();
        setIsNameEmpty(false);
        setEssayData({
          essayName: '',
          sharedWith: [],
        });
      }}
      isLoading={isLoading}
      {...props}
    >
      <div className="w-full flex flex-col gap-6">
        <TextField
          onChange={(event) => {
            setEssayData({
              ...essayData,
              essayName: event.target.value,
            });
          }}
          value={essayData.essayName}
          className="w-full"
          label="Essay Name"
          error={isNameEmpty}
          helperText={isNameEmpty && 'Please enter Essay name'}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
        />
        <SelectTeamMembers
          onChange={(e, teamMembers) => {
            setEssayData({ ...essayData, sharedWith: teamMembers });
          }}
          label="Select Team Member (optional)"
          value={essayData.sharedWith}
          multiple={true}
          menuListTitle="Select your Existing Team Members"
          tooltipTitle="You can select or add Team Members with whom you wish to collaborate"
          options={teamDataReviewers}
        />
      </div>
    </Dialog>
  );
};

export default CopyCoreEssayDialog;
