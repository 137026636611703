import Button from '../../shared/Button';
import AddIcon from '@mui/icons-material/Add';
import { GenericTable } from '../../shared/Table/Table';
import {
  filterUniAndTeamUniversily,
  INVITE_STATUS,
} from '../../../utils/utlityTypes';
import { updateDocument } from '../../../firebase/services/updateServices';
import useProfileStore from '../../../stores/ProfileStore';
import { PROFILE, TEAMS } from '../../../firebase/constants';
import { documentExists } from '../../../firebase/services/auth';
import { CheckCircleRounded } from '@mui/icons-material';
import { getProfileByUserId } from '../../../firebase/services/user';
const columns = [
  { key: 'name', label: 'Name' },
  { key: 'role', label: 'Role' },
  { key: 'dateAdded', label: 'Date Added' },
  { key: 'status', label: 'Status' },
  { key: 'email', label: 'Email Id' },
];

export const rejectRequest = async (teamMembers, teamId, requestedMember) => {
  const updatedMembersList = teamMembers?.filter(
    (member) => member?.uid !== requestedMember
  );
  console.log('reject', {
    updatedMembersList,
    teamMembers,
    teamId,
    requestedMember,
  });
  await updateDocument(
    'teams',
    'id',
    teamId,
    'teamMembers',
    updatedMembersList,
    true
  );
  await updateDocument('profile', 'uid', requestedMember, 'teams', [], true);
};

export const acceptRequest = async (teamMembers, teamId, requestedMember) => {
  const requestedMemberData = await documentExists(
    'profile',
    'uid',
    requestedMember
  );
  const isMember = teamMembers?.some(
    (member) => member?.uid === requestedMember
  );
  const requestedMemberStatus = teamMembers?.find(
    (member) => member?.uid === requestedMember
  )?.invite_status;

  //2 - If the user is already a member of the team, navigate to the team page
  if (isMember && requestedMemberStatus === INVITE_STATUS.JOINED) {
    // TODO (RUTUJA): Show a snackbar message
    return;
  }
  console.log({ isMember, requestedMemberStatus });
  //3 - If the user is not a member of the team, add the user to the team and navigate to the team
  if (isMember && requestedMemberStatus === INVITE_STATUS.REQUESTED_TO_JOIN) {
    console.log('User is not a member of the team');
    const updatedMembersList = teamMembers?.map((member) => {
      if (member?.uid === requestedMember)
        return { ...member, invite_status: INVITE_STATUS.JOINED };
      return member;
    });

    await updateDocument(
      TEAMS,
      'id',
      teamId,
      'teamMembers',
      updatedMembersList,
      true
    );

    const updatedTeamsList = requestedMemberData?.data[0]?.teams?.map(
      (team) => {
        if (team?.teamId === teamId)
          return { ...team, status: INVITE_STATUS.JOINED };
        return team;
      }
    );
    await updateDocument(
      PROFILE,
      'uid',
      requestedMember,
      'teams',
      updatedTeamsList,
      true
    );
    // TODO (RUTUJA): Show a snackbar message
  }
};

export const deleteMember = async (teamMembers, teamId, requestedMember) => {
  const updatedMembersList = teamMembers?.filter(
    (member) => member?.uid !== requestedMember
  );

  await updateDocument(
    'teams',
    'id',
    teamId,
    'teamMembers',
    updatedMembersList,
    true
  );
  const requestedMemberProfile = await getProfileByUserId(requestedMember);
  const newTeams = requestedMemberProfile?.length
    ? requestedMemberProfile[0]?.teams?.filter((team) => team.teamId !== teamId)
    : [];

  await updateDocument(
    'profile',
    'uid',
    requestedMember,
    'teams',
    newTeams,
    true
  );
};

const MyTeamListPanel = ({
  teamMembers = [],
  onAddTeamMember = () => {},
  sendInviteToReviewer = () => {},
}) => {
  const profile = useProfileStore((state) => state.profile);
  let teamId;
  if (profile?.profileType === 'Student') {
    teamId = profile?.teams[0]?.teamId;
  }
  const actions = [
    {
      label: (row) => {
        console.log(row);
        if (row.status === INVITE_STATUS.REQUESTED_TO_JOIN) {
          return (
            <button
              onClick={async () => {
                await acceptRequest(teamMembers, teamId, row.uid);
              }}
            >
              ACCEPT
            </button>
          );
        }
      },
    },
    {
      label: (row) => {
        if (row.uid === profile?.uid) {
          return null;
        }
        if (row.status === INVITE_STATUS.REQUESTED_TO_JOIN) {
          return (
            <Button
              onClick={async () => {
                await rejectRequest(teamMembers, teamId, row.uid);
                // TODO (RUTUJA): Show a snackbar message The user was not accepted to the team
              }}
              startIcon={<CheckCircleRounded />}
            />
          );
        } else {
          return (
            <button
              onClick={async () => {
                await deleteMember(teamMembers, teamId, row.uid);
                // TODO (RUTUJA): Show a snackbar message The user was not accepted to the team
              }}
            >
              DELETE
            </button>
          );
        }
      },
    },
  ];

  console.log(teamMembers);
  const modifiedTeamMembers = teamMembers?.map((teamMember) => {
    return {
      uid: teamMember?.uid,
      name: `${teamMember?.firstName} ${teamMember?.lastName}`,
      role: teamMember?.role,
      dateAdded: teamMember?.dateAdded?.toDate()?.toLocaleDateString(),
      status: teamMember?.invite_status,
      email: teamMember.email,
    };
  });

  return (
    <div className="flex flex-col gap-8 bg-cream-50 sm:p-4 overflow-y-hidden rounded-xl border h-full">
      <div className="flex items-center justify-between py-2 px-4">
        <div className="text-xl sm:text-2xl text-primary-black font-bold">
          My Team
        </div>
        <Button startIcon={<AddIcon />} onClick={onAddTeamMember}>
          Add Team Member
        </Button>
      </div>
      <div className="flex overflow-y-auto">
        <GenericTable
          renderCell={(value, column, row) => value}
          actions={actions}
          columns={columns}
          data={filterUniAndTeamUniversily(modifiedTeamMembers)}
        />
      </div>
    </div>
  );
};

export default MyTeamListPanel;
