import { addStoryItemTypes, firebaseDataType } from '../../constants/other';
import { ASK_UNI_MAIL } from '../../constants/staticData';
import { firebaseQueryOperators } from '../../firebase/queryBuilder';
import { useStoryItemListener } from '../../hooks';
import useChatStore from '../../stores/ChatStore';
import dayjs from '../../utils/dayjs';
import ChatBubble from './ChatBubble';

const ChatBubbleGroup = ({
  currentUserId = '',
  messageList = [],
  receipientDetails = {},
}) => {
  const { isAiTyping } = useChatStore();
  const { data } = useStoryItemListener(
    addStoryItemTypes.PROFILE,
    firebaseDataType.METADATA,
    [
      {
        property: 'email',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: ASK_UNI_MAIL,
      },
    ]
  );

  const isMessageFirstOfType = (messages, index) => {
    if (index > 0) {
      if (messages[index - 1]?.senderId !== messages[index]?.senderId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const isMessageLastOfType = (messages, index) => {
    if (index + 1 <= messages?.length - 1) {
      return messages[index + 1]?.senderId !== messages[index]?.senderId
        ? true
        : false;
    } else {
      return true;
    }
  };

  const getReceiverName = (senderId) => {
    if (receipientDetails?.length > 0) {
      const receiver = receipientDetails?.find(
        (receiver) => receiver.uid === senderId
      );
      return `${receiver?.firstName ?? 'Removed'} ${
        receiver?.lastName ?? 'User'
      }`;
    }
    return `${receipientDetails?.firstName} ${receipientDetails?.lastName}`;
  };

  const getReceiverPhotoURL = (senderId) => {
    if (receipientDetails?.length > 0) {
      const receiver = receipientDetails?.find(
        (receiver) => receiver.uid === senderId
      );
      return receiver?.photoURL;
    }
    return receipientDetails?.photoURL;
  };

  const byDate = messageList.reduce((obj, item) => {
    const date = dayjs.utc(item.timeStamp).local().format('DD MMMM YYYY');
    // eslint-disable-next-line no-unused-vars
    const day = new Date(item.timeStamp).getDay();
    if (obj[date]) {
      obj[date].push(item);
      return obj;
    }

    obj[date] = [{ ...item }];
    return obj;
  }, {});

  return (
    <div>
      {Object.keys(byDate).map((date) => {
        return (
          <div key={date}>
            <div className="w-full flex items-center justify-center text-grey-300 font-sm my-6">
              {dayjs(date).isYesterday() && 'YESTERDAY'}
              {dayjs(date).isToday() && 'TODAY'}
              {!dayjs(date).isYesterday() &&
                !dayjs(date).isToday() &&
                dayjs(date).year() === dayjs().year() &&
                dayjs(date).format('DD MMMM').toUpperCase()}
              {!dayjs(date).isYesterday() &&
                dayjs(date).year() !== dayjs().year() &&
                dayjs(date).format('DD MMMM YYYY').toUpperCase()}
            </div>
            {byDate[date]?.map((message, index) => (
              <ChatBubble
                text={message.messageContent}
                direction={
                  message?.senderId === currentUserId ? 'right' : 'left'
                }
                receiverName={getReceiverName(message.senderId)}
                receiverAvatar={getReceiverPhotoURL(message.senderId)}
                timeStamp={message?.timeStamp}
                firstOfType={isMessageFirstOfType(byDate[date], index)}
                lastOfType={isMessageLastOfType(byDate[date], index)}
                isAI={
                  data && data?.length > 0
                    ? data.at(0)?.uid === message?.senderId
                    : false
                }
              />
            ))}
            {isAiTyping ? (
              <ChatBubble text={'Uni is Typing...'} direction={'left'} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default ChatBubbleGroup;
