export const GenericTable = ({
  columns,
  data,
  renderCell,
  renderRow,
  actions,
}) => {
  return (
    <table className="w-full">
      <thead className="text-xs text-grey-500 border-b border-grey-100">
        <tr>
          {columns.map((column) => (
            <th key={column.key} className="text-left font-normal py-3 px-4">
              {column.label}
            </th>
          ))}
          {actions && actions.length > 0 && (
            <th className="text-left font-normal flex justify-end items-center px-4 py-3">
              Actions
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => {
          if (renderRow) {
            return renderRow(row, rowIndex, columns, actions);
          }

          return (
            <tr
              key={rowIndex}
              className="border-b text-grey-700 text-sm leading-tight"
            >
              {columns.map((column) => (
                <td key={column.key} className="py-3 px-4">
                  {renderCell
                    ? renderCell(row[column.key], column, row)
                    : row[column.key]}
                </td>
              ))}
              {actions && actions.length > 0 && (
                <td className="flex justify-end items-center px-4 py-3">
                  {actions.map((action, actionIndex) => (
                    <button key={actionIndex} className="ml-2">
                      {action.label(row)}
                    </button>
                  ))}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
