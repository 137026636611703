import create from 'zustand';

const useMyHubStore = create((set) => ({
  feedData: [],
  setFeedData: (feedData) => set(() => ({ feedData })),
  isShareUtility: false,
  setIsShareUtility: (isShareUtility) => set(() => ({ isShareUtility })),
  utilityId: null,
  setUtilityId: (utilityId) => set(() => ({ utilityId })),
  utilityType: '',
  setUtilityType: (utilityType) => set(() => ({ utilityType })),
  uilityName: '',
  setUtilityName: (utilityName) => set(() => ({ utilityName })),
}));

export default useMyHubStore;
